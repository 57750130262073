import * as THREE from 'three'
import Experience from "../Experience"

export default class Dragonfly {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.time = this.experience.time
    this.animation = null
    this.mixer = []
    this.animationRunning = false;
    this.dragonfly = this.resources.items.dragonfly

    this.addDragonfly()
    this.addAnimations()
  }

  addToScene(model) {
    model.scene.traverse(child => {
      if (child.isMesh) {
        child.frustumCulled = false
      }
    })
    this.scene.add(model.scene)
  }

  addDragonfly() {
    this.addToScene(this.dragonfly)
  }

  addAnimations() {
    this.mixer.push(new THREE.AnimationMixer(this.dragonfly.scene))
    for (let i = 0; i < this.dragonfly.animations.length; i++) {
      this.animation = this.mixer[this.mixer.length - 1].clipAction(this.dragonfly.animations[i])
      this.animation.play()
    }
    this.animationRunning = true
  }

  update() {
    if (this.animationRunning === true) {
      for (let i = 0; i < this.mixer.length; i++) {
        this.mixer[i].update(this.time.delta * 0.001)
      }
    }
  }
}
