import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

// Connects to data-controller="closing-sequence-modal"
export default class extends Controller {
  connect() {
    const closeModalFunction = this.closeModal
    document.getElementById("carousel-closing-sequence").addEventListener("slide.bs.carousel", function (e) {
      if (e.from == document.querySelectorAll("#closing-sequence-indicators>li").length - 1)
        closeModalFunction()
    })
  }

  closeModal() {
    $("#closing-sequence-slideshow-modal").fadeOut().removeClass("custom-modal-visible")
    document.getElementById("rain-btn").click()
  }
}
