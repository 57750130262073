// https://derk-jan.com/2020/10/rails-ujs-custom-confirm/

import Rails from '@rails/ujs';
import Swal from 'sweetalert2';

let __SkipConfirmation = false;

Rails.confirm = function (message, element) {
  if (__SkipConfirmation) {
    return true;
  }

  Swal.fire({
    title: message,
    icon: 'warning',
    showCancelButton: true
  }).then((result) => {
    if (result.value) {
      __SkipConfirmation = true;
      element.click();
      __SkipConfirmation = false;
    }
  });

  return false;
};
