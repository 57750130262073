import Experience from '../Experience.js'

export default class Stream {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.debug = this.experience.debug
    this.applied = false
    this.waterSubstanceParams = {
      color: '0x217f99',
      opacity: 0.675,
      metalness: 0,
      roughness: 0.25
    }
    this.waterTopParams = {
      color: '0x217f99',
      opacity: 0.9,
      metalness: 0.5,
      roughness: 0.05
    }
    this.displayDebug()
  }

  findStream() {
    if (!this.stream_water_substance) {
      this.stream_water_substance = this.scene.children.find((child) => child.name === 'Water_Substance002')
    }

    if (!this.stream) {
      this.stream = this.scene.children.find((child) => child.name === 'Water_Top')
    }
  }

  setReflection() {
    this.findStream()
    if (this.stream_water_substance != null) {
      this.applied = true
      this.stream_water_substance.material.opacity = this.waterSubstanceParams.opacity
      this.stream_water_substance.material.metalness = this.waterSubstanceParams.metalness
      this.stream_water_substance.material.transparent = true
      this.stream_water_substance.material.color.setHex(this.usableHex(this.waterSubstanceParams.color))
      this.stream_water_substance.material.roughness = 0.25
    }

    if (this.stream) {
      this.applied = true
      this.stream.material.opacity = this.waterTopParams.opacity
      this.stream.material.metalness = this.waterTopParams.metalness
      this.stream.material.transparent = true
      this.stream.material.color.setHex(this.usableHex(this.waterTopParams.color))
      this.stream.material.roughness = 0.25
    }
  }

  updateWaterColorBasedOnScore() {
    const np_level = this.experience.gameResults.nitrousPhosphorousScoreLevel()
    const sed_level = this.experience.gameResults.sedimentScoreLevel()
    let color = this.waterSubstanceParams.color
    let opacity = this.waterSubstanceParams.opacity

    if (np_level == "great") {
      if (sed_level == "great") {
        color = '0x217F99'
        opacity = 0.5
      } else if (sed_level == "ok") {
        color = '0xAE8D5C'
        opacity = 0.55
      } else if (sed_level == "bad") {
        color = '0x8A6020'
        opacity = 0.6
      }
    } else if (np_level == "ok") {
      if (sed_level == "great") {
        color = '0x45BA93'
        opacity = 0.55
      } else if (sed_level == "ok") {
        color = '0xAB9937'
        opacity = 0.6
      } else if (sed_level == "bad") {
        color = '0x8F7722'
        opacity = 0.65
      }
    } else if (np_level == "bad") {
      if (sed_level == "great") {
        color = '0x45BA62'
        opacity = 0.6
      } else if (sed_level == "ok") {
        color = '0xAEB932'
        opacity = 0.65
      } else if (sed_level == "bad") {
        color = '0x8F8102'
        opacity = 0.7
      }
    }

    console.log(`Water Color: ${this.usableHex(color)}`)
    console.log(`Opacity: ${this.usableHex(opacity)}`)

    this.waterSubstanceParams.color = color
    this.waterSubstanceParams.opacity = opacity
    this.waterTopParams.color = color
    this.applied = false
  }

  displayDebug() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('Water')
      this.debugFolder.add(this.waterSubstanceParams, 'opacity').name('Water Substance Opacity').min(0).max(1).step(0.001).onChange(() => {
        this.applied = false
      })
      this.debugFolder.add(this.waterSubstanceParams, 'metalness').name('Water Substance Metalness').min(0).max(1).step(0.001).onChange(() => {
        this.applied = false
      })
      this.debugFolder.add(this.waterSubstanceParams, 'roughness').name('Water Substance Roughness').min(0).max(1).step(0.001).onChange(() => {
        this.applied = false
      })
      this.debugFolder.addColor(this.waterSubstanceParams, 'color').name('Water Substance Color').onFinishChange((e) => {
        this.applied = false
        this.waterSubstanceParams.color = this.usableHex(e)
      })

      this.debugFolder.add(this.waterTopParams, 'opacity').name('Water Top Opacity').min(0).max(1).step(0.001).onChange(() => {
        this.applied = false
      })
      this.debugFolder.add(this.waterTopParams, 'metalness').name('Water Top Metalness').min(0).max(1).step(0.001).onChange(() => {
        this.applied = false
      })
      this.debugFolder.add(this.waterTopParams, 'roughness').name('Water Top Roughness').min(0).max(1).step(0.001).onChange(() => {
        this.applied = false
      })
      this.debugFolder.addColor(this.waterTopParams, 'color').name('Water Top Color').onFinishChange((e) => {
        this.applied = false
        this.waterTopParams.color = this.usableHex(e)
      })
    }
  }

  usableHex(e) {
    e = String(e).replace('#', '0x');
    return e
  }

  update() {
    if (!this.applied) {
      this.setReflection()
    }
  }
}
