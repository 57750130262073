import EventEmitter from './EventEmitter.js'
import * as TWEEN from './tween'

export default class Time extends EventEmitter {
  constructor() {
    super()

    this.start = Date.now()
    this.current = this.start
    this.elapsedTime = 0
    this.delta = 16 //default 60fps per tick would be about 16ms

    window.requestAnimationFrame(() => {
      this.tick()
    })
  }

  tick() {
    const currentTime = Date.now()
    this.delta = currentTime - this.current
    this.current = currentTime
    this.elapsedTime = this.current - this.start
    TWEEN.update(this.elapsedTime)
    this.trigger('tick')
    window.requestAnimationFrame(() => {
      this.tick()
    })
  }
}
