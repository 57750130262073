const animal = document.getElementById('animal-option-button')
if (animal !== null) {
  animal.addEventListener('change', (e) => {
    removeActiveClass()
    document.getElementById('animal-result-container').classList.remove('hidden')
    document.getElementById('alternate-container').classList.add('hidden')
    document.getElementById('results-container').classList.add('hidden')
    addActiveClass(e.currentTarget)
  })
}

const result = document.getElementById('result-option-button')
if (result !== null) {
  result.addEventListener('change', (e) => {
    removeActiveClass()
    document.getElementById('animal-result-container').classList.add('hidden')
    document.getElementById('alternate-container').classList.add('hidden')
    document.getElementById('results-container').classList.remove('hidden')
    addActiveClass(e.currentTarget)
  })
}

const alternate = document.getElementById('alternate-option-button')
if (alternate !== null) {
  alternate.addEventListener('change', (e) => {
    removeActiveClass()
    document.getElementById('animal-result-container').classList.add('hidden')
    document.getElementById('alternate-container').classList.remove('hidden')
    document.getElementById('results-container').classList.add('hidden')
    addActiveClass(e.currentTarget)
  })
}

//If changed, update mouse.js as well
function removeActiveClass () {
  const elements = document.getElementsByClassName('btn-secondary')
  Array.prototype.forEach.call(elements, (el) => {
    el.classList.remove('active')
  })
}

//If changed, update mouse.js as well
function addActiveClass(selectedRadio) {
  const radios = document.getElementsByClassName('result-toggle-radio');

  Array.prototype.forEach.call(radios, (el) => {
    const selector = 'label[for=' + el.id + ']'
    const label = document.querySelector(selector)

    if (el === selectedRadio) {
      label.classList.add('active')
    }
  })
}
