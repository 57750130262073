import Experience from "../Experience"

export default class Landform {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources

    this.addLandform()
  }
  
  addLandform() {
    const children = [...this.resources.items.landformModel.scene.children]
    for (const child of children) {
      this.scene.add(child)
    }
  }
}
