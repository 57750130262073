import * as THREE from 'three'
import Experience from "./Experience"
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera {
  constructor() {
    this.experience = new Experience()
    this.sizes = this.experience.sizes
    this.scene = this.experience.scene
    this.canvas = this.experience.canvas
    this.debug = this.experience.debug

    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('camera')
      this.cubes = {}
    }

    this.setInstance()
    this.setOrbitControls()
    this.displayDebug()
  }

  setInstance() {
    this.instance = new THREE.PerspectiveCamera(
      35,
      this.sizes.width / this.sizes.height,
      0.1,
      100
    )
    this.instance.position.set(-3, 0.1, 9)
    this.scene.add(this.instance)
  }

  setOrbitControls() {
    this.controls = new OrbitControls(this.instance, this.canvas)
    this.controls.enableDamping = true
    this.controls.enabled = this.debug.active
    this.controls.target.set(-1.5, -4.4, -2.9)
    this.controls.maxDistance = 20
  }

  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height
    this.instance.updateProjectionMatrix()
  }

  update() {
    this.controls.update()
  }

  displayDebug() {
    if (this.debug.active) {
      const helper = new THREE.CameraHelper(this.instance);
      this.camParams = {
        cam_x: this.instance.position.x,
        cam_y: this.instance.position.y,
        cam_z: this.instance.position.z,
        cam_rx: this.controls.target.x,
        cam_ry: this.controls.target.y,
        cam_rz: this.controls.target.z
      }
      this.debugFolder
        .add(this.camParams, 'cam_x')
        .min(-20)
        .max(30)
        .step(0.1)
        .onChange((x) => {
          this.instance.position.x = x
          this.instance.updateWorldMatrix()
        })
      this.debugFolder
        .add(this.camParams, 'cam_y')
        .min(-20)
        .max(30)
        .step(0.1)
        .onChange((y) => {
          this.instance.position.y = y
          this.instance.updateWorldMatrix()
        })
      this.debugFolder
        .add(this.camParams, 'cam_z')
        .min(-20)
        .max(30)
        .step(0.1)
        .onChange((z) => {
          this.instance.position.z = z
          this.instance.updateWorldMatrix()
        })

      this.debugFolder
        .add(this.camParams, 'cam_rx')
        .min(-20)
        .max(30)
        .step(0.1)
        .step(0.01)
        .onChange((x) => {
          this.controls.target.x = x
          this.instance.updateWorldMatrix()
        })
      this.debugFolder
        .add(this.camParams, 'cam_ry')
        .min(-20)
        .max(30)
        .step(0.1)
        .step(0.01)
        .onChange((ry) => {
          this.controls.target.y = ry
          this.instance.updateWorldMatrix()
        })
      this.debugFolder
        .add(this.camParams, 'cam_rz')
        .min(-20)
        .max(30)
        .step(0.1)
        .step(0.01)
        .onChange((rz) => {
          this.controls.target.z = rz
          this.instance.updateWorldMatrix()
        })
      const cameraHelperSwitch = { switch: false }
      this.debugFolder.add(cameraHelperSwitch, 'switch').name('Marker camera locations').onChange((e) => {
        if (e === true) {
          this.createMarkerViewLocations()
        } else {
          const keys = Object.keys(this.cubes)
          for (let i = 0; i < keys.length; i++) {
            this.scene.remove(this.cubes[keys[i]]);
          }
          this.cubes = {}
        }
      })

      const controlsSwitch = { switch: false }
      this.debugFolder.add(controlsSwitch, 'switch').name('Free Roam').onChange((e) => {
        this.controls.enabled = e
      })

      const updateCoords = function () {
        console.log(this.instance.position, this.controls.target)
      }
      const boundUpdateCoords = updateCoords.bind(this)
      const cameraOriginPoints = {
        logCameraPosition: boundUpdateCoords
      }
      this.debugFolder.add(cameraOriginPoints, 'logCameraPosition')
    }
  }

  createMarkerViewLocations() {
    const geometry = new THREE.BoxGeometry(0.1, 0.1, 0.1)
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const keys = Object.keys(this.experience.mouse.markerMovementLocations)
    for (let i = 0; i < 10; i++) {
      const positions = this.experience.mouse.markerMovementLocations[keys[i]]
      this.cubes[i] = new THREE.Mesh(geometry, material)
      this.cubes[i].position.x = positions.rx
      this.cubes[i].position.y = positions.ry
      this.cubes[i].position.z = positions.rz
      this.scene.add(this.cubes[i])
    }
  }
}
