import * as THREE from 'three'
import Experience from "../Experience"

export default class Robots {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.time = this.experience.time
    this.animation = null
    this.mixer = []
    this.animationRunning = false;
    this.robots = this.resources.items.robots
    this.robotsFinalScene = this.resources.items.robotsFinalScene

    this.addRobots()
    this.addRobotAnimations()
  }

  addToScene(model) {
    model.scene.traverse(child => {
      if (child.isMesh) {
        child.side = THREE.DoubleSide
        child.frustumCulled = false
      }
    })
    this.scene.add(model.scene)
  }

  addRobots() {
    this.addToScene(this.robots)
  }

  removeRobots() {
    this.scene.remove(this.robots.scene)
    this.mixer.shift()
  }

  addFinalSceneRobots() {
    this.addToScene(this.robotsFinalScene)
    this.twoOhAccessories()
    this.addRobotsFinalSceneAnimations()
  }

  addRobotAnimations() {
    this.mixer.push(new THREE.AnimationMixer(this.robots.scene))
    for (let i = 0; i < this.robots.animations.length; i++) {
      this.animation = this.mixer[this.mixer.length - 1].clipAction(this.robots.animations[i])
      this.animation.play()
    }
    this.animationRunning = true
  }

  addRobotsFinalSceneAnimations() {
    this.mixer.push(new THREE.AnimationMixer(this.robotsFinalScene.scene))
    for (let i = 0; i < this.robotsFinalScene.animations.length; i++) {
      this.animation = this.mixer[this.mixer.length - 1].clipAction(this.robotsFinalScene.animations[i])
      this.animation.play()
    }
    this.animationRunning = true
  }

  twoOhAccessories() {
    const scoreLevel = this.experience.gameResults.scoreLevel()
    if (scoreLevel == 4) {
      //nothing to do since accessories are already visible
      return;
    }

    const two_oh_sphere = this.robotsFinalScene.scene.children.find((child) => child.name === 'Sphere001')
    const backpack = two_oh_sphere.children.find((child) => child.name === "Backpack")
    const leftSwimFloatie = two_oh_sphere.children.find((child) => child.name === "LeftSwimFloatie")
    const rightSwimFloatie = two_oh_sphere.children.find((child) => child.name === "RightSwimFloatie")
    const fishingPole = two_oh_sphere.children.find((child) => child.name === "FishingPole")

    /*
      for reference, if we ever need the goggles
        const robotHead = two_oh_sphere.children.find((child) => child.name === "RobotHead")
        const swimGoggles = robotHead.children.find((child) => child.name === "SwimGoggles")
    */

    // acessories are visible by default so logic is reversed
    //    i.e. hiding the ones we don't want instead of showing the ones we do

    if (scoreLevel == 1) { //one accessory (goggles)
      backpack.visible = false
      leftSwimFloatie.visible = false
      rightSwimFloatie.visible = false
      fishingPole.visible = false
    } else if (scoreLevel == 2) { //two accessories (goggles, backpack)
      leftSwimFloatie.visible = false
      rightSwimFloatie.visible = false
      fishingPole.visible = false
    } else if (scoreLevel == 3) { //three accessories (goggles, backpack, left/right floaties)
      fishingPole.visible = false
    }
  }

  update() {
    if (this.animationRunning === true) {
      for (let i = 0; i < this.mixer.length; i++) {
        this.mixer[i].update(this.time.delta * 0.001)
      }
    }
  }
}
