import * as THREE from 'three'
import Experience from "../Experience"
import * as _ from 'underscore'

export default class Animals {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.time = this.experience.time
    this.animation = {}
    this.mixer = []
    this.animationRunning = false;
    this.animals_group_a = { Deer: {}, Garter_Snake: {}, Ground_Squirrel: {} }
    this.animals_group_b = { Badger: {}, Barred_Owl: {}, Goldfinch: {}, Gray_Fox: {} }
    this.animals_group_c = { Bats: {}, Monarch: {}, Walleye: {} }
    this.animals_group_bonus = { Spotted_Skunk: {}, Topeka_Shiner: {}, Trumpeter_Swan: {} }
    this.animals_group_a.Deer = (this.resources.items.Deer)
    this.animals_group_a.Garter_Snake = (this.resources.items.Garter_Snake)
    this.animals_group_a.Ground_Squirrel = (this.resources.items.Ground_Squirrel)
    this.animals_group_b.Badger = (this.resources.items.Badger)
    this.animals_group_b.Barred_Owl = (this.resources.items.Barred_Owl)
    this.animals_group_b.Goldfinch = (this.resources.items.Goldfinch)
    this.animals_group_b.Gray_Fox = (this.resources.items.Gray_Fox)
    this.animals_group_c.Bats = (this.resources.items.Bats)
    this.animals_group_c.Monarch = (this.resources.items.Monarch)
    this.animals_group_c.Walleye = (this.resources.items.Walleye)
    this.animals_group_c.Walleye.scene.position.x += 0.18
    this.animals_group_bonus.Spotted_Skunk = (this.resources.items.Spotted_Skunk)
    this.animals_group_bonus.Topeka_Shiner = (this.resources.items.Topeka_Shiner)
    this.animals_group_bonus.Trumpeter_Swan = (this.resources.items.Trumpeter_Swan)
    this.placedAnimals = []

  }

  hideAnimals() {
    this.placedAnimals.forEach(animal => {
      this.scene.remove(animal)
    })
    this.placedAnimals = []
  }

  sampleRandomAnimals() {
    this.rand_a = _.sample(this.animals_group_a)
    this.rand_a2 = _.sample(this.animals_group_a, 2)
    this.rand_a_all = _.sample(this.animals_group_a, 3)
    this.rand_b = _.sample(this.animals_group_b)
    this.rand_b2 = _.sample(this.animals_group_b, 2)
    this.rand_b3 = _.sample(this.animals_group_b, 3)
    this.rand_b_all = _.sample(this.animals_group_b, 4)
    this.rand_c = _.sample(this.animals_group_c)
    this.rand_c2 = _.sample(this.animals_group_c, 2)
    this.rand_c_all = _.sample(this.animals_group_c, 3)
    this.rand_bonus = _.sample(this.animals_group_bonus)
  }

  showAnimals(group) {
    this.hideAnimals()
    this.sampleRandomAnimals()
    if (group === 'A1') {
      this.addToScene(this.rand_a.scene)
      this.addAnimations(this.rand_a)
    }
    if (group === 'A2') {
      for (const animal of this.rand_a2) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
    }
    if (group === 'A') {
      for (const animal of this.rand_a_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
    }
    if (group === 'AB1') {
      for (const animal of this.rand_a_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
      this.addToScene(this.rand_b.scene)
      this.addAnimations(this.rand_b)
    }
    if (group === 'AB2') {
      for (const animal of this.rand_a_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
      for (const animal of this.rand_b2) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
    }
    if (group === 'AB3') {
      for (const animal of this.rand_a_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
      for (const animal of this.rand_b3) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
    }
    if (group === 'AB') {
      for (const animal of this.rand_a_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
      for (const animal of this.rand_b_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
    }
    if (group === 'ABC1') {
      for (const animal of this.rand_a_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
      for (const animal of this.rand_b_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
      this.addToScene(this.rand_c.scene)
      this.addAnimations(this.rand_c)

    }
    if (group === 'ABC2') {
      for (const animal of this.rand_a_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
      for (const animal of this.rand_b_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
      for (const animal of this.rand_c2) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
    }
    if (group === 'ABC') {
      for (const animal of this.rand_a_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
      for (const animal of this.rand_b_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
      for (const animal of this.rand_c_all) {
        this.addToScene(animal.scene)
        this.addAnimations(animal)
      }
    }
  }

  addToScene(scene) {
    scene.traverse(
      child => {
        if (child.isMesh) {
          child.side = THREE.DoubleSide
          child.frustumCulled = false
        }
      }
    )
    this.scene.add(scene)
    this.placedAnimals.push(scene)
  }
  showBonusAnimal() {
    this.addToScene(this.rand_bonus.scene)
    this.addAnimations(this.rand_bonus)
  }
  addAnimations(model) {
    this.mixer.push(new THREE.AnimationMixer(model.scene))
    for (let i = 0; i < model.animations.length; i++) {
      this.animation[i] = this.mixer[this.mixer.length - 1].clipAction(model.animations[i])
      this.animation[i].play()
    }
    this.animationRunning = true
  }
  update() {
    if (this.animationRunning === true) {
      for (let i = 0; i < this.mixer.length; i++) {
        this.mixer[i].update(this.time.delta * 0.001)
      }
    }
  }

}
