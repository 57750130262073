import * as THREE from 'three'
import { BufferAttribute } from 'three'
import Experience from "../Experience.js"
import * as _ from 'underscore'


export default class Rain {

  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.debug = this.experience.debug
    this.resources = this.experience.resources
    this.time = this.experience.time
    this.animation = {}
    this.mixer = []
    this.animationRunning = false
    this.severe = null
    this.animationDuration = 0
    this.updateRainXBoundary = false
    this.updateRainZBoundary = false
    this.newRainAnimationRunning = false
    this.rainParameters = {
      rainVelocity: 0.011,
      rainCount: 5000,
      xBoundary: 9.9,
      yBoundary: 10,
      zBoundary: 14.2,
      size: 0.39
    }
    this.rainAlphaMap = this.resources.items.rainNew

    this.debugSettings()
  }

  setMaterial() {
    this.rainGeometry = new THREE.BufferGeometry(1, 32, 32)
    this.rainMaterial = new THREE.PointsMaterial({
      size: this.rainParameters.size,
      sizeAttenuation: true,
      transparent: true,
      alphaMap: this.rainAlphaMap,
      depthWrite: false,
      blending: THREE.AdditiveBlending
    })
  }

  createNewRain(positions) {
    this.setMaterial()
    for (let i = 0; i < this.rainParameters.rainCount * 3; i += 3) {
      //need better calculations for edges of terrain

      positions[i] = (Math.random() - 0.5) * this.rainParameters.xBoundary
      positions[i + 1] = (Math.random() - 0.4) * this.rainParameters.yBoundary
      positions[i + 2] = (Math.random() - 0.5) * this.rainParameters.zBoundary
    }

    this.rainGeometry.setAttribute(
      'position',
      new THREE.BufferAttribute(positions, 3)
    )

    // Rain Drops
    const rain = new THREE.Points(this.rainGeometry, this.rainMaterial)
    this.scene.add(rain)
    this.newRainAnimationRunning = true
  }

  startRainSequence() {
    this.createNewRain(new Float32Array(this.rainParameters.rainCount * 3))
    setTimeout(() => {
      this.stopNewRainAnimation()
    }, 5000)
  }

  createRain(precip_id) {
    this.severe = this.resources.items.Severe
    if (precip_id === 1 || precip_id == 2) {
      //low rainfall
      this.rainParameters.rainVelocity = 0.061
      this.rainParameters.rainCount = 500
      this.rainParameters.size = 0.39
      this.startRainSequence()
      if (precip_id == 2) {
        this.scene.add(this.severe.scene)
        this.addAnimations(this.severe)
      }
    }
    if (precip_id === 3 || precip_id == 4) {
      //medium rainfall
      this.rainParameters.rainVelocity = 0.067
      this.rainParameters.rainCount = 2000
      this.rainParameters.size = 0.39
      this.startRainSequence()
      if (precip_id == 4) {
        this.scene.add(this.severe.scene)
        this.addAnimations(this.severe)
      }
    }
    if (precip_id === 5 || precip_id == 6) {
      //high rainfall
      this.rainParameters.rainVelocity = 0.131
      this.rainParameters.rainCount = 5000
      this.rainParameters.size = 0.39
      this.startRainSequence()
      if (precip_id == 6) {
        this.scene.add(this.severe.scene)
        this.addAnimations(this.severe)
      }
    }
  }

  addAnimations(model) {
    this.mixer.push(new THREE.AnimationMixer(model.scene))
    for (let i = 0; i < model.animations.length; i++) {
      this.animation[i] = this.mixer[this.mixer.length - 1].clipAction(model.animations[i])
      this.animation[i].setLoop(THREE.LoopOnce)
      this.animation[i].play()
      this.animationDuration = this.animation[i]._clip.duration
    }
    this.animationRunning = true
  }

  update() {
    if (this.animationRunning === true) {
      for (let i = 0; i < this.mixer.length; i++) {
        if (this.mixer[i].time >= this.animationDuration * 0.98) {
          this.scene.remove(this.severe.scene)
          this.animationRunning = false
          // since we're removing the model from the scene we also remove the animation mixer from the array
          // this allows the animations to play again on a second play-through
          this.mixer = this.mixer.filter((_element, index) => { return index != i })
          return
        }
        this.mixer[i].update(this.time.delta * 0.001)
      }
    }

    if (this.newRainAnimationRunning === true) {
      for (let i = 1; i < this.rainParameters.rainCount * 3; i += 3) {
        const y = this.rainGeometry.attributes.position.array[i]
        let newY = y - (this.rainParameters.rainVelocity + i % .01 * .1)

        if (y <= 0) {
          newY = Math.random() * 6.75 + 6
        }
        this.rainGeometry.attributes.position.array[i] = newY
      }

      if (this.updateRainX) {
        for (let i = 0; i < this.rainParameters.rainCount * 3; i += 3) {
          let newX = (Math.random() - 0.4) * this.rainParameters.xBoundary
          this.rainGeometry.attributes.position.array[i] = newX
        }
        this.updateRainX = false
      }

      if (this.updateRainZBoundary) {
        for (let i = 2; i < this.rainParameters.rainCount * 3; i += 3) {
          let newZ = (Math.random() - 0.4) * this.rainParameters.zBoundary
          this.rainGeometry.attributes.position.array[i] = newZ
        }
        this.updateRainZBoundary = false
      }
      this.rainGeometry.attributes.position.needsUpdate = true
    }
  }

  stopNewRainAnimation() {
    this.scene.remove(this.scene.children.find((child) => child.type === 'Points'))
    this.newRainAnimationRunning = false
  }

  debugSettings() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('Rain Animations')
      const createRain = this.createRain.bind(this)
      const createNewRain = this.createNewRain.bind(this)
      const stopNewRainAnimation = this.stopNewRainAnimation.bind(this)
      const rainParameters = this.rainParameters
      const animations = {
        createHighRain: function () {
          createRain(1)
        },
        createMediumRain: function () {
          createRain(2)
        },
        createLightRain: function () {
          createRain(3)
        },
        createNewRainClick: function () {
          createNewRain(new Float32Array(rainParameters.rainCount * 3))
        },
        stopNewRainAnimation: function () {
          stopNewRainAnimation()
        },
        rainTexture: {
          texture: 'big'
        }
      }
      this.debugFolder.add(animations, 'createLightRain')
      this.debugFolder.add(animations, 'createMediumRain')
      this.debugFolder.add(animations, 'createHighRain')
      this.debugFolder.add(animations, 'createNewRainClick')
      this.debugFolder.add(animations, 'stopNewRainAnimation')
      this.debugFolder.add(animations.rainTexture, 'texture', ['small', 'medium', 'big']).onChange((value) => {
        this.stopNewRainAnimation()
        if (value === "small") {
          this.rainAlphaMap = this.resources.items.rainSmall
        } else if (value === "medium") {
          this.rainAlphaMap = this.resources.items.rainMedium
        } else {
          this.rainAlphaMap = this.resources.items.rainNew
        }

        this.createNewRain(new Float32Array(this.rainParameters.rainCount * 3))
      })
      this.debugFolder.add(this.rainParameters, 'rainVelocity').min(0).max(0.5).step(0.001)
      this.debugFolder.add(this.rainParameters, 'rainCount').min(0).max(5000).step(10).onChange(() => {
        this.stopNewRainAnimation()
        this.createNewRain(new Float32Array(this.rainParameters.rainCount * 3))
      })
      this.debugFolder.add(this.rainParameters, 'xBoundary').min(0).max(20).step(.1).onChange(() => {
        this.updateRainX = true
      })
      this.debugFolder.add(this.rainParameters, 'zBoundary').min(0).max(20).step(.1).onChange(() => {
        this.updateRainZBoundary = true
      })
      this.debugFolder.add(this.rainParameters, 'size').min(0).max(1).step(.01).onChange(() => {
        this.stopNewRainAnimation()
        this.createNewRain(new Float32Array(this.rainParameters.rainCount * 3))
      })
    }
  }
}
