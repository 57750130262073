import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Sky {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.applied = false

    this.setTexture()
    this.setSky()
  }

  setTexture() {
    this.texture = this.resources.items.paintedSkyTexture
    this.texture.mapping = THREE.EquirectangularReflectionMapping
    this.texture.encoding = THREE.sRGBEncoding;
  }

  setSky() {
    this.scene.background = this.texture
    this.applied = true
  }

  update() {
    if (!this.applied) {
      this.setSky()
    }
  }
}
