import { Controller } from "@hotwired/stimulus"
import Experience from "../src/Experience.js"
import $ from 'jquery'
import { Modal } from 'bootstrap'

// Connects to data-controller="land-selection-modal"
export default class extends Controller {
  static targets = [
    "cornSoybeansSelection",
    "cornSoybeansWithBufferSelection",
    "cornSoybeansWithWetlandSelection",
    "perennialCoverSelection",
    "livestockSelection",
    "housingSelection",
    "parkSelection",
    "golfCourseSelection",
    "commercialSelection"
  ]

  connect() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.boundInitialize = this.initialize.bind(this)
    this.clickedParcelMarker = null
    this.mapControls = this.experience.selection
    this.parcels = this.experience.selectedParcels

    document.getElementById('land-parcel-selection-modal-1').addEventListener('show.bs.modal', this.boundInitialize)
  }

  landUseRuralHeaderClick() {
    if (!this.hasPreviousSelections) {
      this.resetFertilizeRateSelection()
      this.resetTillageSelection()
      this.resetFertilizeSchedule()
      this.resetGrazingTypeSelection()
    }

    $("#fertilizer-rate").hide()
    $("#tillage").hide()
    $("#fertilizer-schedule").hide()
    $("#grazing-type").hide()
    this.expandSelectionSection(".land-use-header")
  }

  landUseRuralSelectionClick(e) {
    if (this.isSelection(e)) {
      if (this.hasPreviousSelections) {
        this.hasPreviousSelections = false
      }

      if (!this.hasPreviousSelections) {
        this.resetLandUseRuralSelection()
        this.toggleSelection(e.target)
      }

      this.showLandUseRuralNextStep()
    }
    e.stopPropagation()
  }

  landUseUrbanHeaderClick() {
    this.resetUrbanHousingDevelopment()
    this.resetCommercialDevelopmentSelection()

    $("#urban-development-housing").hide()
    $("#urban-development-commercial").hide()
    this.expandSelectionSection(".land-use-urban-header")
  }

  landUseUrbanSelectionClick(e) {
    if (this.isSelection(e)) {
      if (this.hasPreviousSelections) {
        this.hasPreviousSelections = false
      }

      if (!this.hasPreviousSelections) {
        this.resetLandUseUrbanSelection()
        this.toggleSelection(e.target)
      }

      this.showLandUseUrbanNextStep()
    }
    e.stopPropagation()
  }

  urbanHousingDevelopmentClick(e) {
    this.resetUrbanHousingDevelopment(e)
    if (this.isSelection(e)) {
      this.toggleSelection(e.target)
      this.applyChanges()
    }
    e.stopPropagation()
  }

  fertilizeRateHeaderClick() {
    if (!this.hasPreviousSelections) {
      this.resetTillageSelection()
      this.resetFertilizeSchedule()
    }

    $("#tillage").hide()
    $("#fertilizer-schedule").hide()
    this.expandSelectionSection(".fertilizer-rate-header")
  }

  fertilizeRateSelectionClick(e) {
    if (this.isSelection(e)) {
      this.resetFertilizeRateSelection()
      this.toggleSelection(e.target)
      this.showFertilizerRateNextStep()
    }
    e.stopPropagation()
  }

  grazingTypeClick(e) {
    if (this.isSelection(e)) {
      this.resetGrazingTypeSelection()
      this.toggleSelection(e.target)
      this.applyChanges()
    }
    e.stopPropagation()
  }

  fertilizerScheduleHeaderClick() {
    this.resetTillageSelection()

    $("#tillage").hide()
    this.expandSelectionSection(".fertilizer-schedule-header")
  }

  fertilizeScheduleSelectionClick(e) {
    if (this.isSelection(e)) {
      this.resetFertilizeSchedule()
      this.toggleSelection(e.target)
      this.showFertilizerScheduleNextStep()
    }
    e.stopPropagation()
  }

  tillageSelectionClick(e) {
    if (this.isSelection(e)) {
      this.resetTillageSelection()
      this.toggleSelection(e.target)
      this.applyChanges()
    }
    e.stopPropagation()
  }

  urbanCommercialDevelopmentClick(e) {
    if (this.isSelection(e)) {
      this.resetCommercialDevelopmentSelection()
      this.toggleSelection(e.target)
      this.applyChanges()
    }
    e.stopPropagation()
  }

  applyChanges() {
    this.saveSelections()
    this.placeLandAsset()

    const landSelectionElement = document.getElementById("land-parcel-selection-modal-1")
    const landSelectionModal = Modal.getInstance(landSelectionElement)
    landSelectionModal.hide()
  }

  isSelection(e) {
    // check if e.target has a parent with specific class
    return Boolean(e.target.closest(".selection"))
  }

  toggleSelection(e) {
    const badge = e.closest(".badge")
    if (badge == null || badge == "undefined") {
      return
    }
    const selection = badge.closest(".selection")
    badge.classList.add("active")
    selection.classList.add("current-selection")

    this.updateTitle(selection)
  }

  updateTitle(selectionItem) {
    const selectionSectionTitle = selectionItem.closest("section").getElementsByClassName("selection-title-selection")[0]
    selectionSectionTitle.innerHTML = ": " + selectionItem.getAttribute("selection-text-value")
  }

  saveSelections() {
    const selectionsData = {
      parcel_id: parseInt(this.clickedParcelMarker.charAt(this.clickedParcelMarker.length - 1)),
      land_use_id: null,
      nitrogen_rate_id: 0,
      tillage_id: 0,
      dom_ids: [],
      parcel_total_score: 0,
      fertilizer_timing_id: 0
    }

    const selections = document.getElementsByClassName("current-selection")
    for (const selection of selections) {
      selectionsData.dom_ids.push(selection.id)
      // change to switch
      if (selection.id === 'land-use-corn-soybeans') {
        selectionsData.land_use_id = 1
      }
      if (selection.id === 'land-use-corn-soybeans-with-buffer') {
        selectionsData.land_use_id = 2
      }
      if (selection.id === 'land-use-corn-soybeans-with-wetland') {
        selectionsData.land_use_id = 3
      }
      if (selection.id === 'land-use-perennial-cover') {
        selectionsData.land_use_id = 4
      }
      if (selection.id === 'grazing-type-traditional-cost') {
        selectionsData.land_use_id = 5
      }
      if (selection.id === 'grazing-type-rotational-cost') {
        selectionsData.land_use_id = 6
      }
      if (selection.id === 'land-use-park') {
        selectionsData.land_use_id = 7
      }
      if (selection.id === 'land-use-golf-course') {
        selectionsData.land_use_id = 8
      }
      if (selection.id === 'urban-housing-development-low-impact') {
        selectionsData.land_use_id = 9
      }
      if (selection.id === 'urban-housing-development-traditional') {
        selectionsData.land_use_id = 10
      }
      if (selection.id === 'urban-commercial-development-low-impact') {
        selectionsData.land_use_id = 11
      }
      if (selection.id === 'urban-commercial-development-traditional') {
        selectionsData.land_use_id = 12
      }
      if (selection.id === 'fertilizer-rate-low-cost') {
        selectionsData.nitrogen_rate_id = 1
      }
      if (selection.id === 'fertilizer-rate-medium-cost') {
        selectionsData.nitrogen_rate_id = 2
      }
      if (selection.id === 'fertilizer-rate-high-cost') {
        selectionsData.nitrogen_rate_id = 3
      }
      if (selection.id === 'tillage-mulch-till') {
        selectionsData.tillage_id = 1
      }
      if (selection.id === 'tillage-strip-till') {
        selectionsData.tillage_id = 2
      }
      if (selection.id === 'tillage-no-till') {
        selectionsData.tillage_id = 3
      }
      if (selection.id === 'tillage-no-till-with-cover-crop') {
        selectionsData.tillage_id = 4
      }
      if (selectionsData.parcel_id === 0) {
        selectionsData.parcel_id = 10
      }
      if (selection.id === 'fertilizer-schedule-split') {
        selectionsData.fertilizer_timing_id = 1
      }
      if (selection.id === 'fertilizer-schedule-spring') {
        selectionsData.fertilizer_timing_id = 2
      }
      if (selection.id === 'fertilizer-schedule-fall') {
        selectionsData.fertilizer_timing_id = 3
      }
    }
    this.parcels[this.clickedParcelMarker] = selectionsData
    if (this.gameComplete()) {
      $(".results-tab-container").fadeOut(1000)
      $("#closing-sequence-slideshow-modal").fadeIn().addClass("custom-modal-visible")
    } else {
      document.getElementById('rain-box').classList.add('close')
    }
    console.log(this.parcels)
  }

  placeLandAsset() {
    /**
     * Land Assets
     */
    let parcel = Number(this.clickedParcelMarker.replace('Parcel_Marker_', ''))
    let landType;
    switch (this.parcels[this.clickedParcelMarker].land_use_id) {
      case 1:
        this.mapControls.cornSoybeans(parcel)
        landType = 'rows'
        break;
      case 2:
        this.mapControls.cornSoybeansBufferStrip(parcel)
        landType = 'buffer'
        break;
      case 3:
        this.mapControls.cornSoybeansWetland(parcel)
        landType = 'wetland'
        break;
      case 4:
        this.mapControls.perennialCover(parcel)
        break;
      case 5:
        this.mapControls.livestockTraditional(parcel)
        break;
      case 6:
        this.mapControls.livestockRotational(parcel)
        break;
      case 7:
        this.mapControls.addpark(parcel);
        break;
      case 8:
        this.mapControls.addGolfCourse(parcel)
        break;
      case 9:
        this.mapControls.residentialLowImpact(parcel)
        break;
      case 10:
        this.mapControls.residentialTraditional(parcel)
        break;
      case 11:
        this.mapControls.commercialLowImpact(parcel)
        break;
      case 12:
        this.mapControls.commercialTraditional(parcel)
        break;
      default:
      // Dont Do anything!!
    }

    /**
     * add tillage
     */
    switch (this.parcels[this.clickedParcelMarker].tillage_id) {
      case 1:
        this.mapControls.mulchTill(parcel)
        break;
      case 2:
        this.mapControls.stripTill(parcel, landType)
        break;
      case 3:
        this.mapControls.noTill(parcel)
        break;
      case 4:
        this.mapControls.noTillCoverCrop(parcel)
        break;
      default:
      // Dont Do anything!!
    }
  }

  showLandUseRuralNextStep() {
    this.swapHeaderIcons(".land-use-header", "collapse")

    if (this.cornSoybeansSelectionTarget.classList.contains("current-selection") || this.cornSoybeansWithBufferSelectionTarget.classList.contains("current-selection") || this.cornSoybeansWithWetlandSelectionTarget.classList.contains("current-selection")) {
      //reset grazing selections
      this.resetGrazingTypeSelection()

      // make sure grazing type is not displayed
      $("#grazing-type").slideUp()

      this.swapHeaderIcons(".fertilizer-rate-header", "expand")

      //show fertilizer rate selections
      $(".fertilizer-rate-header").find(".selections").show()
      $("#fertilizer-rate").slideDown()
    }

    if (this.livestockSelectionTarget.classList.contains("current-selection")) {
      this.resetFertilizeRateSelection()
      this.resetTillageSelection()
      this.resetFertilizeSchedule()

      this.swapHeaderIcons(".grazing-type-header", "expand")

      $("#tillage").slideUp()
      $("#fertilizer-rate").slideUp()
      $("#fertilizer-schedule").slideUp()

      //show grazing type selections
      $(".grazing-type-header").find(".selections").show()
      $("#grazing-type").slideDown()
    }

    if (this.perennialCoverSelectionTarget.classList.contains("current-selection")) {
      this.resetGrazingTypeSelection()
      this.resetFertilizeRateSelection()
      this.resetTillageSelection()
      this.resetFertilizeSchedule()

      this.applyChanges()
    }

    //hide land use selections
    $(".land-use-header").find(".selections").slideUp()
  }

  showLandUseUrbanNextStep() {
    if (this.housingSelectionTarget.classList.contains("current-selection")) {
      this.collapseSelectionSection(".land-use-urban-header")
      this.resetCommercialDevelopmentSelection()

      $("#urban-development-housing").show()
      $(".urban-development-housing-header").find(".selections").slideDown()
    }

    if (this.commercialSelectionTarget.classList.contains("current-selection")) {
      this.collapseSelectionSection(".land-use-urban-header")
      this.resetUrbanHousingDevelopment()

      $("#urban-development-commercial").show()
      $(".urban-development-commercial-header").find(".selections").slideDown()
    }

    if (this.parkSelectionTarget.classList.contains("current-selection") || this.golfCourseSelectionTarget.classList.contains("current-selection")) {
      this.resetCommercialDevelopmentSelection()
      this.resetUrbanHousingDevelopment()

      this.applyChanges()
    }
  }

  showFertilizerRateNextStep() {
    if (this.cornSoybeansSelectionTarget.classList.contains("current-selection") || this.cornSoybeansWithBufferSelectionTarget.classList.contains("current-selection") || this.cornSoybeansWithWetlandSelectionTarget.classList.contains("current-selection")) {
      this.collapseSelectionSection(".fertilizer-rate-header")

      this.swapHeaderIcons(".fertilizer-schedule-header", "expand")

      //show fertilzier schedule
      $(".fertilizer-schedule-header").find(".selections").show()
      $("#fertilizer-schedule").slideDown()
    }
  }

  showFertilizerScheduleNextStep() {
    if (this.cornSoybeansSelectionTarget.classList.contains("current-selection") || this.cornSoybeansWithBufferSelectionTarget.classList.contains("current-selection") || this.cornSoybeansWithWetlandSelectionTarget.classList.contains("current-selection")) {
      this.collapseSelectionSection(".fertilizer-schedule-header")

      this.swapHeaderIcons(".tillage-header", "expand")

      //show tillage
      $("#tillage").slideDown()
    }
  }

  resetLandUseRuralSelection() {
    this.expandSelectionSection(".land-use-header")
    this.resetSelected(".land-use-rural")
  }

  resetLandUseUrbanSelection() {
    this.expandSelectionSection(".land-use-urban-header")
    this.resetSelected(".land-use-urban")
  }

  resetUrbanHousingDevelopment() {
    this.resetSelected(".urban-housing-development")
  }

  resetFertilizeRateSelection() {
    this.resetSelected(".rate")
  }

  resetTillageSelection() {
    this.resetSelected(".tillage")
  }

  resetGrazingTypeSelection() {
    this.resetSelected(".grazing")
  }

  resetFertilizeSchedule() {
    this.resetSelected(".fertilizer-schedule")
  }

  resetCommercialDevelopmentSelection() {
    this.resetSelected(".urban-commercial-development")
  }

  swapHeaderIcons(selector, direction) {
    if (direction === "collapse") {
      $(selector).find(".ellipse-icon").fadeIn()
      $(selector).find(".list-icon").fadeOut()
    } else { // expand
      $(selector).find(".ellipse-icon").fadeOut()
      $(selector).find(".list-icon").fadeIn()
    }
  }

  setPreviousSelections() {
    this.hasPreviousSelections = false
    if (typeof this.parcels === "undefined") {
      return
    }
    this.parcels[this.clickedParcelMarker]?.dom_ids.forEach((item) => {
      const selectionElement = document.getElementById(item)
      selectionElement.querySelector(".badge").classList.add("active")
      selectionElement.classList.add("current-selection")
      this.updateTitle(selectionElement)
      this.hasPreviousSelections = true
    })
  }

  expandSelectionSection(selector) {
    this.swapHeaderIcons(selector, "expand")
    $(selector).find(".selections").slideDown()
  }

  collapseSelectionSection(selector) {
    this.swapHeaderIcons(selector, "collapse")
    $(selector).find(".selections").slideUp()
  }

  resetSelected(selector) {
    $(selector).find(".active").removeClass("active")
    $(selector).find(".current-selection").removeClass("current-selection")
    $(selector).parents("section").find(".selection-title-selection").html("")
  }

  initialize() {
    this.resetLandUseRuralSelection()
    this.resetLandUseUrbanSelection()
    this.resetUrbanHousingDevelopment()
    this.resetFertilizeRateSelection()
    this.resetFertilizeSchedule()
    this.resetGrazingTypeSelection()
    this.resetTillageSelection()
    this.resetCommercialDevelopmentSelection()

    $("#grazing-type").hide()
    $("#fertilizer-schedule").hide()
    $("#fertilizer-rate").hide()
    $("#tillage").hide()
    $("#urban-development-housing").hide()
    $("#urban-development-commercial").hide()

    this.clickedParcelMarker = document.getElementById("land-parcel-selection-modal-1").getAttribute("data-selected-parcel")
    if (["Parcel_Marker_6", "Parcel_Marker_8", "Parcel_Marker_10"].includes(this.clickedParcelMarker)) {
      if (this.clickedParcelMarker == "Parcel_Marker_8") {
        $("#land-use-commercial").hide()
      } else {
        $("#land-use-commercial").show()
      }
      $("#land-use-rural").hide()
      $("#land-use-urban").show()
    } else {
      if (["Parcel_Marker_3", "Parcel_Marker_4", "Parcel_Marker_9"].includes(this.clickedParcelMarker)) {
        $("#land-use-perennial-cover").hide()
      } else {
        $("#land-use-perennial-cover").show()
      }
      $("#land-use-urban").hide()
      $("#land-use-rural").show()
    }

    this.setPreviousSelections()
  }

  gameComplete() {
    return Object.keys(this.parcels).length === 10
  }

  tooltipClick(e) {
    e.stopPropagation();
  }
}
