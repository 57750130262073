import Experience from "../Experience";
import * as THREE from 'three'

export default class Parcels {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.time = this.experience.time
    this.animation = {}
    this.mixer = []
    this.animationRunning = false;
    this.placedParcels = { 1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}, 7: {}, 8: {}, 9: {}, 10: {} }
    this.waterList = []
    this.rowColor = null

    this.parcels = {
      1: {
        parcel_buffer_source_name: 'parcel_1_buffer',
        parcel_wetland_source_name: 'parcel_1_wetland',
        parcel_livestock_traditional_source_name: 'parcel_1_livestock_traditional',
        parcel_livestock_rotational_source_name: 'parcel_1_livestock_rotational',
        parcel_perennial_source_name: 'parcel_1_perennial',
        parcel_corn_source_name: 'parcel_1_corn',
        parcel_strip_till_source_name: 'parcel_1_strip_till',
        parcel_mulch_till_source_name: 'parcel_1_mulch_till',
        parcel_no_till_source_name: 'parcel_1_no_till',
        parcel_no_till_cover_source_name: 'parcel_1_no_till_cover',
        rows: ['Parcel_1_Crop_Row001', 'Parcel_1_Crop_Row002', 'Parcel_1_Crop_Row003', 'Parcel_1_Crop_Row004', 'Parcel_1_Crop_Row005', 'Parcel_1_Crop_Row006', 'Parcel_1_Crop_Row007', 'Parcel_1_Crop_Row008', 'Parcel_1_Crop_Row009', 'Parcel_1_Crop_Row010', 'Parcel_1_Crop_Row011', 'Parcel_1_Crop_Row013', 'Parcel_1_Crop_Row014', 'Parcel_1_Crop_Row015'],
        vectorCount: 14,
        bufferVectorCount: 20,
        stripTillCount: 5
      },
      2: {
        parcel_buffer_source_name: 'parcel_2_buffer',
        parcel_wetland_source_name: 'parcel_2_wetland',
        parcel_livestock_traditional_source_name: 'parcel_2_livestock_traditional',
        parcel_livestock_rotational_source_name: 'parcel_2_livestock_rotational',
        parcel_perennial_source_name: 'parcel_2_perennial',
        parcel_corn_source_name: 'parcel_2_corn',
        parcel_strip_till_source_name: 'parcel_2_strip_till',
        parcel_mulch_till_source_name: 'parcel_2_mulch_till',
        parcel_no_till_source_name: 'parcel_2_no_till',
        parcel_no_till_cover_source_name: 'parcel_2_no_till_cover',
        rows: ['Parcel_2_Crop_Row001', 'Parcel_2_Crop_Row002', 'Parcel_2_Crop_Row003', 'Parcel_2_Crop_Row004', 'Parcel_2_Crop_Row005', 'Parcel_2_Crop_Row006', 'Parcel_2_Crop_Row007', 'Parcel_2_Crop_Row008', 'Parcel_2_Crop_Row009', 'Parcel_2_Crop_Row010', 'Parcel_2_Crop_Row011', 'Parcel_2_Crop_Row012'],
        vectorCount: 14,
        bufferVectorCount: 35,
        stripTillCount: 10
      },
      3: {
        parcel_buffer_source_name: 'parcel_3_buffer',
        parcel_wetland_source_name: 'parcel_3_wetland',
        parcel_livestock_traditional_source_name: 'parcel_3_livestock_traditional',
        parcel_livestock_rotational_source_name: 'parcel_3_livestock_rotational',
        parcel_perennial_source_name: 'parcel_3_perennial',
        parcel_corn_source_name: 'parcel_3_corn',
        parcel_strip_till_source_name: 'parcel_3_strip_till',
        parcel_mulch_till_source_name: 'parcel_3_mulch_till',
        parcel_no_till_source_name: 'parcel_3_no_till',
        parcel_no_till_cover_source_name: 'parcel_3_no_till_cover',
        rows: ['Parcel_3_Crop_Row001', 'Parcel_3_Crop_Row002', 'Parcel_3_Crop_Row003', 'Parcel_3_Crop_Row004', 'Parcel_3_Crop_Row005', 'Parcel_3_Crop_Row006', 'Parcel_3_Crop_Row007', 'Parcel_3_Crop_Row008', 'Parcel_3_Crop_Row009', 'Parcel_3_Crop_Row010', 'Parcel_3_Crop_Row011', 'Parcel_3_Crop_Row012', 'Parcel_3_Crop_Row013', 'Parcel_3_Crop_Row014'],
        vectorCount: 16,
        bufferVectorCount: 30,
        stripTillCount: 5
      },
      4: {
        parcel_buffer_source_name: 'parcel_4_buffer',
        parcel_wetland_source_name: 'parcel_4_wetland',
        parcel_livestock_traditional_source_name: 'parcel_4_livestock_traditional',
        parcel_livestock_rotational_source_name: 'parcel_4_livestock_rotational',
        parcel_perennial_source_name: 'parcel_4_perennial',
        parcel_corn_source_name: 'parcel_4_corn',
        parcel_strip_till_source_name: 'parcel_4_strip_till',
        parcel_mulch_till_source_name: 'parcel_4_mulch_till',
        parcel_no_till_source_name: 'parcel_4_no_till',
        parcel_no_till_cover_source_name: 'parcel_4_no_till_cover',
        rows: ['Parcel_4_Crop_Row001', 'Parcel_4_Crop_Row002', 'Parcel_4_Crop_Row003', 'Parcel_4_Crop_Row004', 'Parcel_4_Crop_Row005', 'Parcel_4_Crop_Row007', 'Parcel_4_Crop_Row008', 'Parcel_4_Crop_Row009', 'Parcel_4_Crop_Row010', 'Parcel_4_Crop_Row011', 'Parcel_4_Crop_Row012', 'Parcel_4_Crop_Row013', 'Parcel_4_Crop_Row014', 'Parcel_4_Crop_Row015', 'Parcel_4_Crop_Row016'],
        vectorCount: 150,
        bufferVectorCount: 150,
        stripTillCount: 15
      },
      5: {
        parcel_buffer_source_name: 'parcel_5_buffer',
        parcel_wetland_source_name: 'parcel_5_wetland',
        parcel_livestock_traditional_source_name: 'parcel_5_livestock_traditional',
        parcel_livestock_rotational_source_name: 'parcel_5_livestock_rotational',
        parcel_perennial_source_name: 'parcel_5_perennial',
        parcel_corn_source_name: 'parcel_5_corn',
        parcel_strip_till_source_name: 'parcel_5_strip_till',
        parcel_mulch_till_source_name: 'parcel_5_mulch_till',
        parcel_no_till_source_name: 'parcel_5_no_till',
        parcel_no_till_cover_source_name: 'parcel_5_no_till_cover',
        rows: ['Parcel_5_Crop_Row001', 'Parcel_5_Crop_Row002', 'Parcel_5_Crop_Row003', 'Parcel_5_Crop_Row004', 'Parcel_5_Crop_Row005', 'Parcel_5_Crop_Row006', 'Parcel_5_Crop_Row007', 'Parcel_5_Crop_Row008', 'Parcel_5_Crop_Row009', 'Parcel_5_Crop_Row010', 'Parcel_5_Crop_Row011', 'Parcel_5_Crop_Row012', 'Parcel_5_Crop_Row013', 'Parcel_5_Crop_Row014', 'Parcel_5_Crop_Row015', 'Parcel_5_Crop_Row016', 'Parcel_5_Crop_Row017', 'Parcel_5_Crop_Row018', 'Parcel_5_Crop_Row019', 'Parcel_5_Crop_Row020', 'Parcel_5_Crop_Row021', 'Parcel_5_Crop_Row022', 'Parcel_5_Crop_Row023', 'Parcel_5_Crop_Row024', 'Parcel_5_Crop_Row025', 'Parcel_5_Crop_Row026', 'Parcel_5_Crop_Row027', 'Parcel_5_Crop_Row028'],
        vectorCount: 21,
        bufferVectorCount: 21,
        stripTillCount: 2
      },
      6: {
        parcel_golf_course_source_name: 'parcel_6_golf_course',
        parcel_park_source_name: 'parcel_6_park',
        parcel_commercial_traditional_source_name: 'parcel_6_commercial_traditional',
        parcel_commercial_low_impact_source_name: 'parcel_6_commercial_low_impact',
        parcel_residential_traditional_source_name: 'parcel_6_residential_traditional',
        parcel_residential_low_impact_source_name: 'parcel_6_residential_low_impact',
        parcel_name: 'Parcel_6_Plane',
      },
      7: {
        parcel_buffer_source_name: 'parcel_7_buffer',
        parcel_wetland_source_name: 'parcel_7_wetland',
        parcel_livestock_traditional_source_name: 'parcel_7_livestock_traditional',
        parcel_livestock_rotational_source_name: 'parcel_7_livestock_rotational',
        parcel_perennial_source_name: 'parcel_7_perennial',
        parcel_corn_source_name: 'parcel_7_corn',
        parcel_strip_till_source_name: 'parcel_7_strip_till',
        parcel_mulch_till_source_name: 'parcel_7_mulch_till',
        parcel_no_till_source_name: 'parcel_7_no_till',
        parcel_no_till_cover_source_name: 'parcel_7_no_till_cover',
        rows: ['Parcel_7_Crop_Row001', 'Parcel_7_Crop_Row002', 'Parcel_7_Crop_Row003', 'Parcel_7_Crop_Row004', 'Parcel_7_Crop_Row005', 'Parcel_7_Crop_Row006', 'Parcel_7_Crop_Row007', 'Parcel_7_Crop_Row008', 'Parcel_7_Crop_Row009', 'Parcel_7_Crop_Row010', 'Parcel_7_Crop_Row011', 'Parcel_7_Crop_Row012', 'Parcel_7_Crop_Row013', 'Parcel_7_Crop_Row014', 'Parcel_7_Crop_Row015', 'Parcel_7_Crop_Row016'],
        vectorCount: 20,
        bufferVectorCount: 30,
        stripTillCount: 5
      },
      8: {
        parcel_golf_course_source_name: 'parcel_8_golf_course',
        parcel_park_source_name: 'parcel_8_park',
        parcel_residential_traditional_source_name: 'parcel_8_residential_traditional',
        parcel_residential_low_impact_source_name: 'parcel_8_residential_low_impact',
        parcel_name: 'Parcel_8_Plane',
      },
      9: {
        parcel_buffer_source_name: 'parcel_9_buffer',
        parcel_wetland_source_name: 'parcel_9_wetland',
        parcel_livestock_traditional_source_name: 'parcel_9_livestock_traditional',
        parcel_livestock_rotational_source_name: 'parcel_9_livestock_rotational',
        parcel_perennial_source_name: 'parcel_9_perennial',
        parcel_corn_source_name: 'parcel_9_corn',
        parcel_strip_till_source_name: 'parcel_9_strip_till',
        parcel_mulch_till_source_name: 'parcel_9_mulch_till',
        parcel_no_till_source_name: 'parcel_9_no_till',
        parcel_no_till_cover_source_name: 'parcel_9_no_till_cover',
        rows: ['Parcel_9_Crop_Row001', 'Parcel_9_Crop_Row002', 'Parcel_9_Crop_Row004', 'Parcel_9_Crop_Row005', 'Parcel_9_Crop_Row006', 'Parcel_9_Crop_Row007', 'Parcel_9_Crop_Row008', 'Parcel_9_Crop_Row009', 'Parcel_9_Crop_Row010', 'Parcel_9_Crop_Row011', 'Parcel_9_Crop_Row012', 'Parcel_9_Crop_Row013', 'Parcel_9_Crop_Row014', 'Parcel_9_Crop_Row015', 'Parcel_9_Crop_Row016', 'Parcel_9_Crop_Row017', 'Parcel_9_Crop_Row018', 'Parcel_9_Crop_Row019', 'Parcel_9_Crop_Row020', 'Parcel_9_Crop_Row021', 'Parcel_9_Crop_Row022'],
        vectorCount: 30,
        bufferVectorCount: 35,
        stripTillCount: 7
      },
      10: {
        parcel_golf_course_source_name: 'parcel_10_golf_course',
        parcel_park_source_name: 'parcel_10_park',
        parcel_commercial_traditional_source_name: 'parcel_10_commercial_traditional',
        parcel_commercial_low_impact_source_name: 'parcel_10_commercial_low_impact',
        parcel_residential_traditional_source_name: 'parcel_10_residential_traditional',
        parcel_residential_low_impact_source_name: 'parcel_10_residential_low_impact',
        parcel_name: 'Parcel_10_Plane',
      }
    }
  }

  addParcelToMap(parcel, type) {
    if (type === 'buffer') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_buffer_source_name].scene)
      //this.setMaterialToDirt(this.resources.items[this.parcels[parcel].parcel_buffer_source_name].scene)
    } else if (type === 'wetland') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_wetland_source_name].scene)
      //this.setMaterialToDirt(this.resources.items[this.parcels[parcel].parcel_wetland_source_name].scene)
      this.addAnimation(this.resources.items[this.parcels[parcel].parcel_wetland_source_name])
    } else if (type === 'golf') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_golf_course_source_name].scene)
      this.addAnimation(this.resources.items[this.parcels[parcel].parcel_golf_course_source_name])
    } else if (type === 'park') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_park_source_name].scene)
    } else if (type === 'livestock_traditional') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_livestock_traditional_source_name].scene)
      this.addAnimation(this.resources.items[this.parcels[parcel].parcel_livestock_traditional_source_name])
    } else if (type === 'livestock_rotational') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_livestock_rotational_source_name].scene)
      this.addAnimation(this.resources.items[this.parcels[parcel].parcel_livestock_rotational_source_name])
    } else if (type === 'commercial_traditional') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_commercial_traditional_source_name].scene)
    } else if (type === 'commercial_low_impact') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_commercial_low_impact_source_name].scene)
    } else if (type === 'residential_traditional') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_residential_traditional_source_name].scene)
    } else if (type === 'residential_low_impact') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_residential_low_impact_source_name].scene)
    } else if (type === 'perennial_cover') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_perennial_source_name].scene)
    } else if (type === 'corn') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_corn_source_name].scene)
    } else if (type === 'strip_till') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_strip_till_source_name].scene)
    } else if (type === 'mulch_till') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_mulch_till_source_name].scene)
    } else if (type === 'no_till') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_no_till_source_name].scene)
    } else if (type === 'no_till_cover') {
      this.raiseAndDisplayScene(parcel, this.resources.items[this.parcels[parcel].parcel_no_till_cover_source_name].scene)
    }
  }

  raiseAndDisplayScene(parcel, scene) {
    scene.traverse(child => {
      if (child instanceof THREE.Mesh) {
        child.side = THREE.DoubleSide
        child.frustumCulled = false
      }
    }
    )
    this.scene.add(scene)
    this.placedParcels[parcel][Object.keys(this.placedParcels[parcel]).length] = scene
  }

  removeOldParcelSelection(parcel) {
    if (this.placedParcels[parcel] != null) {
      const keys = Object.keys(this.placedParcels[parcel])
      for (let i = 0; i < keys.length; i++) {
        this.scene.remove(this.placedParcels[parcel][keys[i]])
      }
    }
  }

  addAnimation(model) {
    this.mixer.push(new THREE.AnimationMixer(model.scene))
    for (let i = 0; i < model.animations.length; i++) {
      this.animation[i] = this.mixer[this.mixer.length - 1].clipAction(model.animations[i])
      this.animation[i].play()
    }
    this.animationRunning = true
  }



  updateReflections() {
    const keys = Object.keys(this.waterList)
    for (let i = 0; i < keys.length; i++) {
      this.waterList[keys[i]].material = this.experience.world.stream.material.clone()
      this.waterList[keys[i]].material.opacity = 1
    }
  }

  update() {
    this.updateReflections()
    if (this.animationRunning === true) {
      for (let i = 0; i < this.mixer.length; i++) {
        this.mixer[i].update(this.time.delta * 0.001)
      }
    }
  }
}
