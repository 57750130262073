import { Turbo } from '@hotwired/turbo-rails';

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    turboModalId: String,
    reset: Boolean,
    message: String,
    redirect: String
  };

  connect() {
    const modal = document.getElementById(this.turboModalIdValue);
    const modalCloseButton = document.createElement('button');
    modalCloseButton.dataset.bsDismiss = 'modal';
    modal.append(modalCloseButton);
    modalCloseButton.click();
    modalCloseButton.remove();

    if (this.resetValue) {
      const turboFrame = modal.querySelector('turbo-frame');
      turboFrame.parentElement.insertBefore(turboFrame.cloneNode(), turboFrame);
      turboFrame.remove();
    }

    if (this.hasMessageValue) {
      (new ToastMessage(this.messageValue)).show();
    }

    if (this.hasRedirectValue) {
      Turbo.visit(this.redirectValue);
    }

    this.element.remove();
  }
}
