// Entry point for the build script in your package.json
import '@oddcamp/cocoon-vanilla-js';
import '@rails/actiontext';
import * as ActiveStorage from '@rails/activestorage';
import Rails from '@rails/ujs';
import 'bootstrap';
import LocalTime from 'local-time';
import 'trix';
import './src';
import 'bootstrap-datepicker'
import $ from 'jquery'
// https://github.com/hotwired/turbo-rails/issues/79#issuecomment-761608052
import './controllers';
import '@hotwired/turbo-rails';
import ClassWatcher from './src/ClassWatcher'

Turbo.session.drive = false

$('.datepicker').datepicker({
  dateFormat: 'mm-dd-yyyy'
});

function detectMobile() {
  const isMobile = window.innerWidth < 768;
  const onMobileMessagePage = window.location.pathname.match(/\/mobile$/);

  if (isMobile) {
    if (!onMobileMessagePage) {
      window.location = "mobile";
    }
  } else {
    if (onMobileMessagePage) {
      window.location = "/";
    }
  }
}

detectMobile();

addEventListener("resize", (_event) => {
  detectMobile();
});

const carousel_modals = Array.from(document.querySelectorAll(".carousel-modal"))
carousel_modals.forEach((modal) => {
  new ClassWatcher(modal, 'custom-modal-visible', customModalShown, customModalNotShown)

  function customModalShown() {
    document.getElementsByTagName("body")[0].classList.add("modalShown")
    document.querySelector('.custom-backdrop').classList.remove('hidden')
  }

  function customModalNotShown() {
    document.getElementsByTagName("body")[0].classList.remove("modalShown")
    document.querySelector('.custom-backdrop').classList.add('hidden')
  }
})

Rails.start();
LocalTime.start();
ActiveStorage.start();
