import Experience from "../Experience.js"
import Environment from './Environment.js'
import Landform from './Landform.js'
import Sky from './Sky.js'
import Stream from './Stream.js'
import Rain from './Rain.js'
import Animals from './Animals.js'
import Robots from './Robots.js'
import Dragonfly from './Dragonfly.js'

export default class World {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources

    this.resources.on('ready', () => {
      this.landform = new Landform()
      this.sky = new Sky()
      this.stream = new Stream()
      this.environment = new Environment()
      this.rain = new Rain()
      this.animals = new Animals()
      this.robots = new Robots()
      this.dragonfly = new Dragonfly()
    })
  }

  update() {
    if (this.sky) {
      this.sky.update()
    }
    if (this.stream) {
      this.stream.update()
    }
    if (this.rain) {
      this.rain.update()
    }
    if (this.animals) {
      this.animals.update()
    }
    if (this.robots) {
      this.robots.update()
    }
    if (this.dragonfly) {
      this.dragonfly.update()
    }
  }
}
