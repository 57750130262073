import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

// Connects to data-controller="non-river-modal"
export default class extends Controller {
  connect() {
    const closeModalFunction = this.closeModal
    document.getElementById("carousel-non-river").addEventListener("slide.bs.carousel", function (e) {
      if (e.from == document.querySelectorAll("#non-river-indicators>li").length - 1) {
        closeModalFunction()
      }
    })
  }

  closeModal() {
    document.getElementById("non-river-slideshow-modal").setAttribute("hasDisplayed", true)
    $("#non-river-slideshow-modal").fadeOut().removeClass("custom-modal-visible")
    document.getElementById("land-selection-modal-link").click()
    $(".results-tab-container").hide()
  }
}
