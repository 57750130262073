import { Controller } from '@hotwired/stimulus';

import { ToastMessage } from '../src/toast_message';

export default class extends Controller {
  static values = {
    message: String,
    bgColor: String
  };

  connect() {
    (new ToastMessage(
      this.messageValue,
      this.bgColorValue === '' ? undefined : this.bgColorValue
    )).show();

    this.element.remove();
  }
}
