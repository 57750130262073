export default [
  {
    name: 'landformModel',
    type: 'gltfModel',
    path: 'models/landform.glb'
  },
  {
    name: "dragonfly",
    type: "gltfModel",
    path: 'models/Dragonfly.glb'
  },
  // Robots
  {
    name: 'robots',
    type: 'gltfModel',
    path: 'models/robots/robots.glb'
  },
  {
    name: 'robotsFinalScene',
    type: 'gltfModel',
    path: 'models/robots/Robots_FinalScene.glb'
  },
  // Sky
  {
    name: 'paintedSkyTexture',
    type: 'texture',
    path: 'textures/environmentMaps/BG_3.jpeg'
  },
  // Rain
  {
    name: "rainNew",
    type: "texture",
    path: 'textures/rain/rain-big.png'
  },
  {
    name: "rainSmall",
    type: "texture",
    path: 'textures/rain/rain-small.png'
  },
  {
    name: "rainMedium",
    type: "texture",
    path: 'textures/rain/rain-med.png'
  },
  {
    name: 'Severe',
    type: 'gltfModel',
    path: 'models/rain/Lightning.glb'
  },
  // Parcels
  {
    name: 'parcel_1_corn',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_1_Corn.glb'
  },
  {
    name: 'parcel_1_strip_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_1_Strip_Till.glb'
  },
  {
    name: 'parcel_1_no_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_1_No_Till.glb'
  },
  {
    name: 'parcel_1_no_till_cover',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_1_No_Till_Cover.glb'
  },
  {
    name: 'parcel_1_mulch_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_1_Mulch_Till.glb'
  },
  {
    name: 'parcel_1_buffer',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_1_Buffer_Strip.glb'
  },
  {
    name: 'parcel_1_wetland',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_1_Wetland.glb'
  },
  {
    name: 'parcel_1_perennial',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_1_Perennial.glb'
  },
  {
    name: 'parcel_1_livestock_traditional',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_1_Livestock_Traditional.glb'
  },
  {
    name: 'parcel_1_livestock_rotational',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_1_Livestock_Rotational.glb'
  },
  {
    name: 'parcel_2_corn',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_2_Corn.glb'
  },
  {
    name: 'parcel_2_strip_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_2_Strip_Till.glb'
  },
  {
    name: 'parcel_2_no_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_2_No_Till.glb'
  },
  {
    name: 'parcel_2_no_till_cover',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_2_No_Till_Cover.glb'
  },
  {
    name: 'parcel_2_mulch_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_2_Mulch_Till.glb'
  },
  {
    name: 'parcel_2_buffer',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_2_Buffer_Strip.glb'
  },
  {
    name: 'parcel_2_wetland',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_2_Wetland.glb'
  },
  {
    name: 'parcel_2_perennial',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_2_Perennial.glb'
  },
  {
    name: 'parcel_2_livestock_traditional',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_2_Livestock_Traditional.glb'
  },
  {
    name: 'parcel_2_livestock_rotational',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_2_Livestock_Rotational.glb'
  },
  {
    name: 'parcel_3_corn',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_3_Corn.glb'
  },
  {
    name: 'parcel_3_strip_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_3_Strip_Till.glb'
  },
  {
    name: 'parcel_3_no_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_3_No_Till.glb'
  },
  {
    name: 'parcel_3_no_till_cover',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_3_No_Till_Cover.glb'
  },
  {
    name: 'parcel_3_mulch_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_3_Mulch_Till.glb'
  },
  {
    name: 'parcel_3_buffer',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_3_Buffer_Strip.glb'
  },
  {
    name: 'parcel_3_wetland',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_3_Wetland.glb'
  },
  {
    name: 'parcel_3_perennial',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_3_Perennial.glb'
  },
  {
    name: 'parcel_3_livestock_traditional',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_3_Livestock_Traditional.glb'
  },
  {
    name: 'parcel_3_livestock_rotational',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_3_Livestock_Rotational.glb'
  },
  {
    name: 'parcel_4_corn',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_4_Corn.glb'
  },
  {
    name: 'parcel_4_strip_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_4_Strip_Till.glb'
  },
  {
    name: 'parcel_4_no_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_4_No_Till.glb'
  },
  {
    name: 'parcel_4_no_till_cover',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_4_No_Till_Cover.glb'
  },
  {
    name: 'parcel_4_mulch_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_4_Mulch_Till.glb'
  },
  {
    name: 'parcel_4_buffer',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_4_Buffer_Strip.glb'
  },
  {
    name: 'parcel_4_wetland',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_4_Wetland.glb'
  },
  {
    name: 'parcel_4_perennial',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_4_Perennial.glb'
  },
  {
    name: 'parcel_4_livestock_traditional',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_4_Livestock_Traditional.glb'
  },
  {
    name: 'parcel_4_livestock_rotational',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_4_Livestock_Rotational.glb'
  },
  {
    name: 'parcel_5_corn',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_5_Corn.glb'
  },
  {
    name: 'parcel_5_strip_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_5_Strip_Till.glb'
  },
  {
    name: 'parcel_5_no_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_5_No_Till.glb'
  },
  {
    name: 'parcel_5_no_till_cover',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_5_No_Till_Cover.glb'
  },
  {
    name: 'parcel_5_mulch_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_5_Mulch_Till.glb'
  },
  {
    name: 'parcel_5_buffer',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_5_Buffer_Strip.glb'
  },
  {
    name: 'parcel_5_wetland',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_5_Wetland.glb'
  },
  {
    name: 'parcel_5_perennial',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_5_Perennial.glb'
  },
  {
    name: 'parcel_5_livestock_traditional',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_5_Livestock_Traditional.glb'
  },
  {
    name: 'parcel_5_livestock_rotational',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_5_Livestock_Rotational.glb'
  },
  {
    name: 'parcel_6_golf_course',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_6_Golf_Course.glb'
  },
  {
    name: 'parcel_6_commercial_traditional',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_6_Commercial_Traditional.glb'
  },
  {
    name: 'parcel_6_commercial_low_impact',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_6_Commercial_Low_Impact.glb'
  },
  {
    name: 'parcel_6_park',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_6_Park.glb'
  },
  {
    name: 'parcel_6_residential_traditional',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_6_Residential_Traditional.glb'
  },
  {
    name: 'parcel_6_residential_low_impact',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_6_Residential_Low_Impact.glb'
  },
  {
    name: 'parcel_7_corn',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_7_Corn.glb'
  },
  {
    name: 'parcel_7_strip_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_7_Strip_Till.glb'
  },
  {
    name: 'parcel_7_no_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_7_No_Till.glb'
  },
  {
    name: 'parcel_7_no_till_cover',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_7_No_Till_Cover.glb'
  },
  {
    name: 'parcel_7_mulch_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_7_Mulch_Till.glb'
  },
  {
    name: 'parcel_7_buffer',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_7_Buffer_Strip.glb'
  },
  {
    name: 'parcel_7_wetland',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_7_Wetland.glb'
  },
  {
    name: 'parcel_7_perennial',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_7_Perennial.glb'
  },
  {
    name: 'parcel_7_livestock_traditional',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_7_Livestock_Traditional.glb'
  },
  {
    name: 'parcel_7_livestock_rotational',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_7_Livestock_Rotational.glb'
  },
  {
    name: 'parcel_8_golf_course',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_8_Golf_Course.glb'
  },
  {
    name: 'parcel_8_park',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_8_Park.glb'
  },
  {
    name: 'parcel_8_residential_traditional',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_8_Residential_Traditional.glb'
  },
  {
    name: 'parcel_8_residential_low_impact',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_8_Residential_Low_Impact.glb'
  },
  {
    name: 'parcel_9_corn',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_9_Corn.glb'
  },
  {
    name: 'parcel_9_strip_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_9_Strip_Till.glb'
  },
  {
    name: 'parcel_9_no_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_9_No_Till.glb'
  },
  {
    name: 'parcel_9_no_till_cover',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_9_No_Till_Cover.glb'
  },
  {
    name: 'parcel_9_mulch_till',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_9_Mulch_Till.glb'
  },
  {
    name: 'parcel_9_buffer',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_9_Buffer_Strip.glb'
  },
  {
    name: 'parcel_9_wetland',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_9_Wetland.glb'
  },
  {
    name: 'parcel_9_perennial',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_9_Perennial.glb'
  },
  {
    name: 'parcel_9_livestock_traditional',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_9_Livestock_Traditional.glb'
  },
  {
    name: 'parcel_9_livestock_rotational',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_9_Livestock_Rotational.glb'
  },
  {
    name: 'parcel_10_golf_course',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_10_Golf_Course.glb'
  },

  {
    name: 'parcel_10_park',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_10_Park.glb'
  },
  {
    name: 'parcel_10_commercial_traditional',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_10_Commercial_Traditional.glb'
  },
  {
    name: 'parcel_10_commercial_low_impact',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_10_Commercial_Low_Impact.glb'
  },
  {
    name: 'parcel_10_residential_traditional',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_10_Residential_Traditional.glb'
  },
  {
    name: 'parcel_10_residential_low_impact',
    type: 'gltfModel',
    path: 'models/parcels/Parcel_10_Residential_Low_Impact.glb'
  },
  // Animals
  {
    name: 'Deer',
    type: 'gltfModel',
    path: 'models/animals/GroupA/Deer_Export_Group_A.glb'
  },
  {
    name: 'Garter_Snake',
    type: 'gltfModel',
    path: 'models/animals/GroupA/Garter_Snake_Export_Group_A.glb'
  },
  {
    name: 'Ground_Squirrel',
    type: 'gltfModel',
    path: 'models/animals/GroupA/Ground_Squirrel_Export_Group_A.glb'
  },
  {
    name: 'Badger',
    type: 'gltfModel',
    path: 'models/animals/GroupB/Badger_Export_Group_B.glb'
  },
  {
    name: 'Goldfinch',
    type: 'gltfModel',
    path: 'models/animals/GroupB/Goldfinch_Export_Group_B.glb'
  },
  {
    name: 'Barred_Owl',
    type: 'gltfModel',
    path: 'models/animals/GroupB/Barred_Owl_Export_Group_B.glb'
  },
  {
    name: 'Gray_Fox',
    type: 'gltfModel',
    path: 'models/animals/GroupB/Gray_Fox_Export_Group_B.glb'
  },
  {
    name: 'Monarch',
    type: 'gltfModel',
    path: 'models/animals/GroupC/Monarch_Export_Group_C.glb'
  },
  {
    name: 'Bats',
    type: 'gltfModel',
    path: 'models/animals/GroupC/Bats_Export_Group_C.glb'
  },
  {
    name: 'Walleye',
    type: 'gltfModel',
    path: 'models/animals/GroupC/Walleye_Export_Group_C.glb'
  },
  {
    name: 'Spotted_Skunk',
    type: 'gltfModel',
    path: 'models/animals/GroupBonus/Spotted_Skunk_Export_Group_Bonus.glb'
  },
  {
    name: 'Topeka_Shiner',
    type: 'gltfModel',
    path: 'models/animals/GroupBonus/Topeka_Shiner_Export_Group_Bonus.glb'
  },
  {
    name: 'Trumpeter_Swan',
    type: 'gltfModel',
    path: 'models/animals/GroupBonus/Trumpeter_Swan_Export_Group_Bonus.glb'
  },
]
