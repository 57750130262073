import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
import Experience from "../src/Experience.js";

export default class extends Controller {
  static values = { game: Boolean }

  connect() {
    this.experience = new Experience()
    this.mouse = this.experience.mouse
    this.video = document.getElementById("game-loading-video");
    this.cameraOrigin = this.mouse.cameraOrigin
    this.mouse.moveCameraTo(this.cameraOrigin)

    const gameLoadingModalElement = document.getElementById("game-loading-modal")
    this.gameLoadingModal = Modal.getOrCreateInstance(gameLoadingModalElement)

    this.video.addEventListener("ended", (event) => {
      document.getElementById("skip-loading-video").classList.add("hidden")
      document.getElementById("watch-again-loading-video").classList.remove("hidden")
      document.getElementById("play-game-loading-video").classList.remove("hidden")

      this.video.setAttribute("videoPlayed", true)
    });
  }

  playVideo() {
    this.video.currentTime = 0
    this.video.play()
  }

  dismissModal() {
    this.gameLoadingModal.hide()
  }
}
