import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import Experience from "../src/Experience.js";

export default class extends Controller {
  static values = { game: Boolean }
  static targets = [
    'startbtn',
    'xbtn'
  ];

  connect() {
    // TODO: remove this controller and associated views for new game / not new game
    return;
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.mouse = this.experience.mouse
    this.cameraOrigin = this.mouse.cameraOrigin
    if (this.gameValue) {
      this.mouse.moveCameraTo(this.cameraOrigin)
    }
  }

  startbtnClick() {
    if (!this.gameValue) {
      $('#bottom-box-not-new-game').addClass('close')
      this.mouse.moveCameraTo(this.cameraOrigin)
      $('#bottom-box-not-new-game').slideToggle("slow")
      $('#bottom-box-new-game').toggleClass('close')
    }
  }

  xbtnClick() {
    if (this.gameValue) {
      this.mouse.moveCameraTo(this.cameraOrigin)
      $('#bottom-box-new-game').toggleClass('close')
    } else {
      $('#bottom-box-new-game').slideToggle("slow")
    }


  }
}
