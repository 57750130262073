import { Controller } from '@hotwired/stimulus';

import $ from 'jquery';
import select2 from 'select-woo';

export default class extends Controller {
  initialize() {
    this.select2OpenHandler = this.select2Open.bind(this);
    this.changeHandler = this.change.bind(this);

    select2();
  }

  connect() {
    const $select = this.$select;

    const select2Opts = {
      closeOnSelect: !this.element.multiple,
      selectionCssClass: ':all:',
      theme: 'bootstrap-5'
    };

    const emptyOption = Array.from(this.element.options).find(o => o.value === '');
    if (emptyOption !== undefined) {
      select2Opts.placeholder = emptyOption.textContent;
      select2Opts.allowClear = true;
    }

    $select.select2(select2Opts);

    const label = document.createElement('label');
    label.htmlFor = this.element.id;
    label.style.display = $select.data('select2').$container.css('display');
    label.classList.add('select2-offscreen'); // Has no effect besides hiding from capybara-select-2 where necessary
    $select.data('select2').$container.wrap(label);

    // https://github.com/select2/select2/issues/5993
    $select.on('select2:open', this.select2OpenHandler);

    $select.on('change', this.changeHandler);
  }

  disconnect() {
    const $select = this.$select;

    $select.off('change', this.changeHandler);

    $select.off('select2:open', this.select2OpenHandler);

    $select.select2('destroy');
  }

  get $select() {
    return $(this.element);
  }

  select2Open() {
    const searchField =
      this.$select.data('select2').$dropdown.find('.select2-search__field')[0];

    if (searchField === undefined) {
      return;
    }

    searchField.focus();
  }

  // Select2 triggers the jQuery `change` event, not the DOM `change` event;
  // this makes it trigger the DOM event too.
  change(e) {
    if (!e.isTrigger) {
      return;
    }

    this.element.dispatchEvent(new Event('change'));
  }
}
