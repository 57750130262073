export default class GameResults {
  constructor() {
    this.gameData = {}
  }

  scoreLevel() {
    const score = this.gameData.total_score

    if (score >= 0 && score < 700) {
      return 1
    }
    if (score >= 700 && score < 775) {
      return 2
    }
    if (score >= 775 && score < 850) {
      return 3
    }

    // score >= 850
    return 4
  }

  nitrousPhosphorousScoreLevel() {
    const score = (this.gameData.nitrogen_total + this.gameData.phosphorus_total) / 2
    console.log("np_level", score)

    if (score >= 800) { //&& score <= 1000)
      return "great"
    } else if (score >= 600 && score <= 799) {
      return "ok"
    } else if (score <= 599) {
      return "bad"
    }
  }

  sedimentScoreLevel() {
    const score = this.gameData.sediment_total

    console.log("sed total", score)

    if (score >= 800) { //&& score <= 1000)
      return "great"
    } else if (score >= 600 && score <= 799) {
      return "ok"
    } else if (score <= 599) {
      return "bad"
    }
  }
}
