import * as THREE from 'three'
import Experience from "../Experience.js"

export default class Environment {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.debug = this.experience.debug

    this.lightColors = {}
    this.lightColors.sunLight = '#FFF6EB'
    this.lightColors.centerLight = '#FFF6EB'
    this.lightColors.frontCenter = '#FFF6EB'
    this.lightColors.frontRight = '#FFF6EB'
    this.lightColors.frontLeft = '#FFF6EB'
    this.lightColors.backRight = '#FFF6EB'
    this.lightColors.hemisphereLightSky = '#FFF6EB'
    this.lightColors.hemisphereLightGround = '#37A7BF'
    this.lightColors.ambientLight = '#FFEBEE'

    this.frontLeftLightTarget = new THREE.Object3D()
    this.frontLeftLightTarget.position.x = 5
    this.frontLeftLightTarget.position.y = 0.21
    this.frontLeftLightTarget.position.z = 0.1

    this.frontRightLightTarget = new THREE.Object3D()
    this.frontRightLightTarget.position.x = -3.23
    this.frontRightLightTarget.position.y = 2.44
    this.frontRightLightTarget.position.z = 0.3;

    this.fog = {
      color: 'rgb(188,248,244)',
      density: 0.02
    }

    this.setSunLight()
    this.setFog()
    this.addHemisphereLight()
    this.setAmbientLight()
    this.displayDebug()
  }

  setSunLight() {
    this.centerLight = new THREE.PointLight(this.lightColors.centerLight, 0.07, 2, 5)
    this.centerLight.position.set(0.5, -0.04, 8.5)
    this.scene.add(this.centerLight)

    this.frontCenter = new THREE.PointLight(this.lightColors.frontCenter, 0.5, 5)
    this.frontCenter.position.set(-2.6, 0.5, 8.5)
    this.scene.add(this.frontCenter)

    this.frontRight = new THREE.DirectionalLight(this.lightColors.frontRight, 3)
    this.frontRight.position.set(-5, 4.1, -2.4)
    this.scene.add(this.frontRightLightTarget)
    this.frontRight.target = this.frontRightLightTarget
    this.scene.add(this.frontRight)

    this.frontLeft = new THREE.DirectionalLight(this.lightColors.frontLeft, 1.5)
    this.frontLeft.position.set(-5, 0.6, 2.18)
    this.scene.add(this.frontLeftLightTarget)
    this.frontLeft.target = this.frontLeftLightTarget
    this.scene.add(this.frontLeft)

    this.backRight = new THREE.PointLight(this.lightColors.backRight, 1, 20)
    this.backRight.position.set(-3.64, 2.18, 0.41)
    this.scene.add(this.backRight)
  }

  addHemisphereLight() {
    this.hemisphereLight = new THREE.HemisphereLight(this.lightColors.hemisphereLightSky, this.lightColors['hemisphereLightGround'], 1)
    this.hemisphereLight.position.set(0, 50, 2)
    this.scene.add(this.hemisphereLight)
  }

  setAmbientLight() {
    this.ambientLight = new THREE.AmbientLight(this.lightColors.ambientLight, 0.55)
    this.scene.add(this.ambientLight)
  }

  setFog() {
    this.scene.fog = new THREE.FogExp2(this.fog.color, this.fog.density)
  }

  displayDebug() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('lights')
      this.debugFolder.add(this.hemisphereLight, 'intensity').name('hemisphereLightIntensity').min(0).max(100).step(0.001)
      this.debugFolder.add(this.ambientLight, 'intensity').name('ambientLightIntensity').min(0).max(100).step(0.001)

      const pointLightHelperSwitch = { switch: false }
      const sphereSize = 1;
      //this.pointLightHelper1 = new THREE.PointLightHelper(this.sunLight, sphereSize)
      this.pointLightHelper2 = new THREE.PointLightHelper(this.centerLight, sphereSize)
      this.pointLightHelper3 = new THREE.PointLightHelper(this.frontCenter, sphereSize)
      this.pointLightHelper4 = new THREE.PointLightHelper(this.backRight, sphereSize)


      this.debugFolder.add(pointLightHelperSwitch, 'switch').name('showPointLightHelpers').onChange((e) => {
        if (e === true) {
          this.scene.add(this.pointLightHelper1)
          this.scene.add(this.pointLightHelper2)
          this.scene.add(this.pointLightHelper3)
          this.scene.add(this.pointLightHelper4)
        } else {
          this.scene.remove(this.pointLightHelper1)
          this.scene.remove(this.pointLightHelper2)
          this.scene.remove(this.pointLightHelper3)
          this.scene.remove(this.pointLightHelper4)
        }
      })

      this.debugfrontLightFolder = this.debug.ui.addFolder('Front Lights')
      this.debugfrontLightFolder.add(this.frontCenter, 'intensity').name('FrontCenterIntensity').min(0).max(100).step(0.001)
      this.debugfrontLightFolder.add(this.frontCenter, 'distance').name('FrontCenter_Distance').min(0).max(50).step(0.01)
      this.debugfrontLightFolder.add(this.frontCenter.position, 'x').name('FrontCenter_X').min(-5).max(10).step(0.01)
      this.debugfrontLightFolder.add(this.frontCenter.position, 'y').name('FrontCenter_Y').min(-5).max(20).step(0.01)
      this.debugfrontLightFolder.add(this.frontCenter.position, 'z').name('FrontCenter_Z').min(-5).max(10).step(0.01)

      this.debugfrontLightFolder.add(this.frontRight, 'intensity').name('FrontRightIntensity').min(0).max(100).step(0.001)
      this.debugfrontLightFolder.add(this.frontRight.position, 'x').name('FrontRight_X').min(-5).max(10).step(0.01)
      this.debugfrontLightFolder.add(this.frontRight.position, 'y').name('FrontRight_Y').min(-5).max(20).step(0.01)
      this.debugfrontLightFolder.add(this.frontRight.position, 'z').name('FrontRight_Z').min(-5).max(10).step(0.01)
      this.debugfrontLightFolder.add(this.frontRightLightTarget.position, 'x').name('FrontRightTarget_X').min(-5).max(10).step(0.01).onChange(() => {
        this.frontRight.target.updateMatrixWorld();
      })
      this.debugfrontLightFolder.add(this.frontRightLightTarget.position, 'y').name('FrontRightTarget_Y').min(-5).max(20).step(0.01)
      this.debugfrontLightFolder.add(this.frontRightLightTarget.position, 'z').name('FrontRightTarget_Z').min(-5).max(10).step(0.01)

      this.debugfrontLightFolder.add(this.frontLeft, 'intensity').name('FrontLeftIntensity').min(0).max(100).step(0.001)
      this.debugfrontLightFolder.add(this.frontLeft.position, 'x').name('FrontLeft_X').min(-5).max(10).step(0.01)
      this.debugfrontLightFolder.add(this.frontLeft.position, 'y').name('FrontLeft_Y').min(-5).max(20).step(0.01)
      this.debugfrontLightFolder.add(this.frontLeft.position, 'z').name('FrontLeft_Z').min(-5).max(10).step(0.01)
      this.debugfrontLightFolder.add(this.frontLeftLightTarget.position, 'x').name('FrontLeftTarget_X').min(-5).max(10).step(0.01).onChange(() => {
        this.frontLeft.target.updateMatrixWorld();
      })
      this.debugfrontLightFolder.add(this.frontLeftLightTarget.position, 'y').name('FrontLeftTarget_Y').min(-5).max(20).step(0.01)
      this.debugfrontLightFolder.add(this.frontLeftLightTarget.position, 'z').name('FrontLeftTarget_Z').min(-5).max(10).step(0.01)

      this.debugCenterLightFolder = this.debug.ui.addFolder('Center light')
      this.debugCenterLightFolder.add(this.centerLight, 'intensity').name('centerLight_Intensity').min(0).max(100).step(0.001)
      this.debugCenterLightFolder.add(this.centerLight, 'distance').name('CenterLight_Distance').min(0).max(50).step(0.01)
      this.debugCenterLightFolder.add(this.centerLight.position, 'x').name('CenterLight_X').min(-5).max(10).step(0.01)
      this.debugCenterLightFolder.add(this.centerLight.position, 'y').name('CenterLight_Y').min(-5).max(20).step(0.01)
      this.debugCenterLightFolder.add(this.centerLight.position, 'z').name('CenterLight_Z').min(-5).max(10).step(0.01)

      this.debugRearLightFolder = this.debug.ui.addFolder('Rear light')
      this.debugRearLightFolder.add(this.backRight, 'intensity').name('RearLight_Intensity').min(0).max(100).step(0.001)
      this.debugRearLightFolder.add(this.backRight, 'distance').name('RearLight_Distance').min(0).max(50).step(0.01)
      this.debugRearLightFolder.add(this.backRight.position, 'x').name('RearLight_X').min(-5).max(10).step(0.01)
      this.debugRearLightFolder.add(this.backRight.position, 'y').name('RearLight_Y').min(-5).max(20).step(0.01)
      this.debugRearLightFolder.add(this.backRight.position, 'z').name('RearLight_Z').min(-5).max(10).step(0.01)

      this.debugFogFolder = this.debug.ui.addFolder('Fog')
      this.debugFogFolder.add(this.fog, 'color').onChange(() => {
        this.setFog()
      })
      this.debugFogFolder.add(this.fog, 'density').onChange(() => {
        this.setFog()
      })
    }
  }

}
