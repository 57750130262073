import * as dat from 'lil-gui'

export default class Debug {
  constructor() {
    this.active = window.location.hash === '#debug'

    if(this.active) {
      this.ui = new dat.GUI()
      const addFolder = this.ui.addFolder;
      this.ui.addFolder = function(...args) {
        return addFolder.call(this, ...args).close()
      }
    }
  }
}