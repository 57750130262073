import * as THREE from 'three'
import Experience from "./Experience.js"

export default class Renderer {
  constructor() {
    this.experience = new Experience()

    this.scene = this.experience.scene
    this.canvas = this.experience.canvas
    this.sizes = this.experience.sizes
    this.camera = this.experience.camera
    this.debug = this.experience.debug

    this.setInstance()
    this.debugSettings()
  }

  setInstance() {
    this.instance = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true
    })
    this.instance.physicallyCorrectLights = true
    this.instance.gammaOutput = true
    this.instance.outputEncoding = THREE.sRGBEncoding
    this.instance.toneMapping = THREE.LinearEncoding
    this.instance.toneMappingExposure = 1.4
    this.instance.gammaFactor = 2
    this.instance.setClearColor('#32a4dc')
    this.instance.setSize(this.sizes.width, this.sizes.height)
    this.instance.setPixelRatio(this.sizes.pixelRatio)
  }

  resize() {
    this.instance.setSize(this.sizes.width, this.sizes.height)
    this.instance.setPixelRatio(this.sizes.pixelRatio)
  }

  debugSettings() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('Rendering')
      this.debugFolder.add(this.instance, 'gammaFactor')
      this.debugFolder.add(this.instance, 'toneMappingExposure')
      this.debugFolder.add(this.instance, 'toneMapping', { no_tone: THREE.NoToneMapping, linear: THREE.LinearToneMapping, cineon: THREE.CineonToneMapping, aces_filmic: THREE.ACESFilmicToneMapping })
      this.debugFolder.add(this.instance, 'outputEncoding', { srgb_encoding: THREE.sRGBEncoding, linear_encoding: THREE.LinearEncoding })
    }
  }

  update() {
    this.instance.render(this.scene, this.camera.instance)
  }
}
