import * as THREE from 'three'
import Experience from "./Experience.js"
import * as TWEEN from './Utils/tween'
import { Modal } from 'bootstrap'
import $ from 'jquery'

export default class Mouse {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.camera = this.experience.camera
    this.controls = this.camera.controls
    this.cursorPosition = { x: 0, y: 0 }
    this.objectsToTest = []
    this.currentIntersect = null
    this.debug = this.experience.debug

    this.debugClicks = {
      run: true
    }
    this.displayDebug()

    this.markers = [
      'Parcel_Marker_1',
      'Parcel_Marker_2',
      'Parcel_Marker_3',
      'Parcel_Marker_4',
      'Parcel_Marker_5',
      'Parcel_Marker_6',
      'Parcel_Marker_7',
      'Parcel_Marker_8',
      'Parcel_Marker_9',
      'Parcel_Marker_10',
      'Marker_Deer',
      'Marker_Snake',
      'Marker_Monarch',
      'Marker_Fox',
      'Marker_Owl',
      'Marker_Badger',
      'Marker_Ground_Squirrel',
      'Marker_Walleye',
      'Marker_Swan',
      'Marker_Goldfinch',
      'Marker_SpottedSkunk',
      'Marker_TopekaShiner',
      'Marker_Bat',
      'Marker_TwoOh'
    ]

    this.easterEggs = [
      'rock014',
      'rock015'
    ]

    this.raycaster = new THREE.Raycaster
    this.rayDirection = new THREE.Vector3(-1, -1, -1)
    this.downRay = new THREE.Raycaster
    this.cameraOrigin = { x: -0.2, y: 2.5, z: 16.5, rx: -0.5, ry: 0.6, rz: 5 }
    this.makeRainOrigin = { x: -0.2, y: 3.5, z: 19.6, rx: -0.5, ry: 0.8, rz: 5 }
    this.markerMovementLocations = {
      'Parcel_Marker_1': { x: -3.5, y: 1.9, z: 0, rx: -7.5, ry: 0, rz: -6.88 },
      'Parcel_Marker_2': { x: 0.5, y: 1.1, z: 0.2, rx: -5.03, ry: -0.9, rz: -8 },
      'Parcel_Marker_3': { x: -2.6, y: 1.5, z: 3, rx: -8.1, ry: -0.5, rz: -4 },
      'Parcel_Marker_4': { x: 0.5, y: 1.2, z: 4, rx: -5.5, ry: -0.9, rz: -5 },
      'Parcel_Marker_5': { x: -1, y: 0.8, z: 6, rx: -6, ry: -0.5, rz: -4 },
      'Parcel_Marker_6': { x: -0.6, y: 0.5, z: 8.8, rx: -9.9, ry: -1, rz: -5 },
      'Parcel_Marker_7': { x: -0.3, y: 0.9, z: 2.5, rx: 2.9, ry: -0.2, rz: -3 },
      'Parcel_Marker_8': { x: 3.2, y: 1.6, z: 2.7, rx: 5, ry: 0.5, rz: -4 },
      'Parcel_Marker_9': { x: -0.9, y: 1.4, z: 6.0, rx: 8, ry: -1.55, rz: -4 },
      'Parcel_Marker_10': { x: 3, y: 1, z: 9.5, rx: 1.5, ry: 0, rz: -2 },
      'Marker_Deer': { x: -0.9, y: 0, z: 7.6, rx: -1.75, ry: -2.8, rz: -5 },
      'Marker_Snake': { x: 0, y: -0.2, z: -0.1, rx: 0.75, ry: -3.25, rz: -5 },
      'Marker_Monarch': { x: -1.4, y: -0.3, z: -0.7, rx: -1.5, ry: -1.3, rz: -3.75 },
      'Marker_Fox': { x: -0.2, y: -0.3, z: 2.7, rx: 10, ry: -4, rz: 2.7 },
      'Marker_Owl': { x: 1.1, y: 0.3, z: 7.8, rx: 2.5, ry: 0, rz: 0 },
      'Marker_Badger': { x: 3, y: -0.1, z: 7.6, rx: 3.6, ry: -0.5, rz: 0 },
      'Marker_Ground_Squirrel': { x: -0.8, y: -0.4, z: 2.2, rx: 0, ry: -0.8, rz: -1.5 },
      'Marker_Walleye': { x: -1.2, y: -0.5, z: 10.2, rx: -0.3, ry: -0.3, rz: 0 },
      'Marker_Swan': { x: 0.5, y: -0.2, z: 1, rx: 1.9, ry: -1.5, rz: -2 },
      'Marker_Goldfinch': { x: 0, y: -0.3, z: -3.6, rx: -.20, ry: -0.5, rz: -5 },
      'Marker_SpottedSkunk': { x: 0.7, y: -0.4, z: 8.4, rx: 1, ry: -0.7, rz: 8 },
      'Marker_TopekaShiner': { x: -0.8, y: -1, z: 9, rx: -0.31, ry: -0.31, rz: 0 },
      'Marker_Bat': { x: -4.5, y: 0.1, z: 7.4, rx: -4.47, ry: 0.14, rz: 7.35 },
      'Marker_TwoOh': { x: -0.4, y: 0.2, z: 9, rx: -0.07, ry: -2.82, rz: -3.36 }
    }

    this.markerNonRiverLocations = [
      'Parcel_Marker_1',
      'Parcel_Marker_3',
      'Parcel_Marker_8'
    ]

    this.markerRiverLocations = [
      'Parcel_Marker_2',
      'Parcel_Marker_4',
      'Parcel_Marker_5',
      'Parcel_Marker_6',
      'Parcel_Marker_7',
      'Parcel_Marker_9',
      'Parcel_Marker_10'
    ]

    let goTo = {}
    window.addEventListener('click', () => {
      if (!this.debugClicks.run) {
        return
      }
      const landSelectionElement = document.getElementById("land-parcel-selection-modal-1")
      const modal = Modal.getInstance(landSelectionElement)
      if (modal?._isShown) {
        return
      }

      const customModalDisplayed = document.getElementsByTagName("body")[0].classList.contains("modalShown")
      if (customModalDisplayed) {
        return
      }
      if (this.currentIntersect) {
        let markerName = this.currentIntersect.object.parent.name
        if (markerName == '') {
          //Targets sent through the glb optizer have an additional layer so need to go up another parent level in some cases
          markerName = this.currentIntersect.object.parent?.parent?.name
          if (markerName == '') {
            //One target does not have a name
            markerName = this.currentIntersect.object.name
          }
        }
        if (markerName in this.markerMovementLocations) {
          goTo = this.markerMovementLocations[markerName]
          this.moveCameraTo(goTo)

          let animalText
          let animalHeader

          if (markerName === 'Marker_Deer') {
            animalText = `The white-tailed deer is a mostly-nocturnal mammal
            found commonly in timber, crop fields, and grassy areas across the state of
            Iowa. The white-tailed deer is a primary consumer or herbivore, affecting
            the ecosystem with its ability to strongly influence the composition of the
            plant community through its grazing.`
            animalHeader = 'White-tailed Deer'
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName === 'Marker_Snake') {
            animalText = `The Plains garter snake is one of 27 snake species found in Iowa.
            Snakes are important predators in the food chain, consuming worms, slugs, insects, birds, and
            amphibians, while also helping to control populations of small mammals/rodents.`
            animalHeader = 'Plains Garter Snake'
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName === 'Marker_Monarch') {
            animalText = `The monarch butterfly is iconic of all pollinators. Plants and pollinators are
            defined by mutualism – each depends on the other, and the reduction or loss of either will affect
             the survival of both. Consider planting milkweed to help feed monarchs and other pollinators!`
            animalHeader = 'Monarch Butterfly'
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName === 'Marker_Fox') {
            animalText = `The gray fox is a carnivore or meat-eater. Its feeding habits allow it to influence
            small rodent populations by maintaining a steady predator-prey relationship; however, it also
            serves as food for larger mammals and birds of prey. The gray fox is unique -  it is the only
            member of the dog family that can climb trees!`
            animalHeader = 'Gray Fox'
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName === 'Marker_Owl') {
            animalText = `The barred owl is a carnivore, acting as an important predator in the ecosystem. It
            roosts on branches and in tree cavities during the day and hunts for small mammals such as rabbits,
            squirrels, chipmunks, and mice (prey that can be swallowed whole) at night. Owls help keep the population
            of crop damaging rodents under control.`
            animalHeader = 'Barred Owl'
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName === 'Marker_Badger') {
            animalText = `The badger is a burrowing mammal that is classified as an omnivore - an
            important consumer of small prey in the ecosystem. Much of its food is found underground,
            captured through vigorous digging, including small ground-dwelling rodents, birds,
            amphibians, earthworms, insects, and grubs, plus fruits and seeds.`
            animalHeader = 'American Badger'
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName === 'Marker_Ground_Squirrel') {
            animalText = `The thirteen-lined ground squirrel, commonly known as the squinney, represents small
            mammals. Rodents and other small mammals play numerous important roles within ecosystems, including
            soil aeration (digging/tunneling), and serving as prey for larger animals.`
            animalHeader = 'Thirteen Lined Ground Squirrel'
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName === 'Marker_Walleye') {
            animalText = `The abundance and quality of fish species present is one indicator of water quality
            and the health of the ecosystem as a whole, represented here by the native walleye. As top predators,
            walleyes have a large, complex role in the aquatic ecosystem. Although adult walleyes are at the
            top of their food chain, their larvae, eggs, and young are near the bottom.`
            animalHeader = 'Walleye'
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName === 'Marker_Swan') {
            animalText = `Trumpeter swans are the largest waterfowl species in the world. These beautiful,
            majestic birds have a wingspan that is up to eight feet across and they can weigh up to
            thirty-five pounds each.`
            animalHeader = 'Trumpeter Swan'
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName === 'Marker_Goldfinch') {
            animalText = `The American Goldfinch, Iowa’s official state bird, likes to eat seeds from grass,
            thistles, and other plants, helping in the dispersal of seeds across the ecosystem. It is preyed upon
            by larger birds and snakes, as well as domestic cats and other mammals. The goldfinch represents
            songbirds, which have seen some of the largest population declines across the animal kingdom in
            recent years.`
            animalHeader = 'American Goldfinch'
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName === 'Marker_SpottedSkunk') {
            animalText = `The spotted skunk is smaller than its familiar cousin the striped skunk, and this
            endangered species also has the decidedly unique quirk of doing a handstand. This acrobatic
            isn’t for your entertainment but to warn you that it is about to spray!`
            animalHeader = 'Spotted Skunk'
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName === 'Marker_TopekaShiner') {
            animalText = `Little fish, big world – that’s the story of the Topeka Shiner! Native to Iowa, the
            Topeka Shiner is an endangered species that is just 3” long when fully grown. The T. Shiner’s
            preferred habitat includes the slow-moving, cool water of prairie streams and river oxbows.`
            animalHeader = 'Topeka Shiner'
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName === 'Marker_Bat') {
            animalHeader = 'Big Brown Bat'
            animalText = `The big brown bat uses echolocation to avoid obstacles during flight and to
            capture insects such as beetles for its next meal. Eating only in the warm months when insects
            are alive, the big brown bat consumes many insect pests, including the corn root worm, one of
            the worst agricultural pests in the United States.`
            showResultAnimals()
            this.removeActiveClass()
            this.addActiveClass()
          } else if (markerName == 'Marker_TwoOh') {
            const scoreLevel = this.experience.gameResults.scoreLevel()
            animalHeader = 'Two-Oh'
            if (scoreLevel == 1) {
              animalText = `Your watershed is polluted, and that will affect Two-Oh’s summer plans. Keep those swim goggles on tight, buddy!`
            } else if (scoreLevel == 2) {
              animalText = `Your watershed isn’t clean and healthy, so Two-Oh will need to scale back on summer activities. Try getting a better score to unlock more accessories.`
            } else if (scoreLevel == 3) {
              animalText = `Your watershed is looking pretty good, so Two-Oh can enjoy a lot of activities, but there’s still room for improvement. A higher score will unlock more.`
            } else if (scoreLevel == 4) {
              animalText = `Your watershed is healthy and vibrant, so Two-Oh gets to enjoy all the fun summer activities: Swimming, fishing, hiking, and more!`
            }
            $(".results-tab-container").show() //can be hidden if they click a marker then click off the selection modal without selecting anything
            document.getElementById("effects-tab").click()
          } else {
            //set selected marker and display dialog box
            landSelectionElement.setAttribute("data-selected-parcel", markerName)

            if (this.markerNonRiverLocations.includes(markerName) && !document.getElementById("non-river-slideshow-modal").getAttribute("hasDisplayed")) {
              $("#non-river-slideshow-modal").fadeIn().addClass("custom-modal-visible")
              return
            }

            if (this.markerRiverLocations.includes(markerName) && !document.getElementById("river-slideshow-modal").getAttribute("hasDisplayed")) {
              $("#river-slideshow-modal").fadeIn().addClass("custom-modal-visible")
              return
            }

            document.getElementById("land-selection-modal-link").click()
            $(".results-tab-container").hide()
          }

          if (animalText) {
            document.getElementById('animal-result-text').innerHTML = animalText
            document.getElementById('animal-result-header').innerHTML = animalHeader
          }

          if (this.easterEggs.includes(markerName)) {
            this.controls.target.set(this.currentIntersect.object.position.x,
              this.currentIntersect.object.position.y,
              this.currentIntersect.object.position.z)
          }
        }
        if (this.easterEggs.includes(markerName)) {
          goTo = {
            x: this.camera.instance.position.x,
            y: this.camera.instance.position.y,
            z: this.camera.instance.position.z,
            rx: this.currentIntersect.object.position.x,
            ry: this.currentIntersect.object.position.y,
            rz: this.currentIntersect.object.position.z
          }
          this.moveCameraTo(goTo)
        }
      }

      window.addEventListener('dblclick', () => {
        const customModalDisplayed = document.getElementsByTagName("body")[0].classList.contains("modalShown")
        if (customModalDisplayed) {
          return false
        }
        const modal = Modal.getInstance(landSelectionElement)
        if (modal?._isShown && this.currentIntersect && this.debugClicks.run) {
          return false
        }

        this.moveCameraTo(this.cameraOrigin)
      })

      window.addEventListener('mousemove', (event) => {
        this.cursorPosition.x = (event.clientX / window.innerWidth) * 2 - 1
        this.cursorPosition.y = -(event.clientY / window.innerHeight) * 2 + 1
      })

      function showResultAnimals() {
        $(".results-tab-container").show() //can be hidden if they click a marker then click off the selection modal without selecting anything
        document.getElementById("effects-tab").click()
      }
    })
  }

  moveCameraTo(goTo) {
    let cords = {
      x: this.camera.instance.position.x,
      y: this.camera.instance.position.y,
      z: this.camera.instance.position.z,
      rx: this.controls.target.x,
      ry: this.controls.target.y,
      rz: this.controls.target.z
    }

    new TWEEN.Tween(cords)
      .to({ x: goTo.x, y: goTo.y, z: goTo.z, rx: goTo.rx, ry: goTo.ry, rz: goTo.rz })
      .onUpdate(() => {
        this.camera.instance.position.set(cords.x, cords.y, cords.z)
        this.controls.target.set(cords.rx, cords.ry, cords.rz)
      }).start()
  }

  // cameraLimit() {
  //   const intersects = this.raycaster.intersectObjects(this.scene.children)
  //   for (let i = 0; i < intersects.length; i++) {
  //     if (intersects[i].distance < 0.05) {
  //       this.camera.instance.position.y += 0.1
  //     }
  //   }
  //   const intersects2 = this.downRay.intersectObjects(this.scene.children)
  //    for (let i = 0; i < intersects2.length; i++) {
  //    if (intersects2[i].distance < 0.05) {
  //      this.camera.instance.position.y += 0.1
  //      }
  //    }
  // }

  displayDebug() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('Mouse Clicks')
      this.debugFolder.add(this.debugClicks, 'run')
    }
  }
  //If changed, update game_results.js as well
  removeActiveClass() {
    const elements = document.getElementsByClassName('btn-secondary')
    Array.prototype.forEach.call(elements, (el) => {
      el.classList.remove('active')
    })
  }
  //If changed, update game_results.js as well
  addActiveClass() {
    const radios = document.getElementsByClassName('result-toggle-radio');
    const animal = document.getElementById('animal-option-button');

    Array.prototype.forEach.call(radios, (el) => {
      const selector = 'label[for=' + el.id + ']'
      const label = document.querySelector(selector)

      if (el === animal) {
        label.classList.add('active')
      }
    })
  }

  update() {
    //this.cameraLimit()
    this.raycaster.setFromCamera(this.cursorPosition, this.camera.instance)
    this.downRay.setFromCamera(this.rayDirection, this.camera.instance)
    this.objectsToTest = []
    for (const marker of this.markers) {
      let obj = this.scene.children.find((child) => child.name === marker)
      if (obj) {
        this.objectsToTest.push(obj)
      } else {
        this.scene.children.forEach((child) => {
          if (child.name === "Scene") {
            obj = child.children.find((child) => child.name === marker)
            if (obj) {
              this.objectsToTest.push(obj)
            }
          }
        })
      }
    }
    for (const egg of this.easterEggs) {
      const obj = this.scene.children.find((child) => child.name === egg)
      if (obj) {
        this.objectsToTest.push(obj)
      }
    }
    const intersects = this.raycaster.intersectObjects(this.objectsToTest)
    if (intersects.length) {
      this.currentIntersect = intersects[0]
    } else {
      this.currentIntersect = null
    }
  }
}
