import Parcels from "./World/Parcels.js";

export default class Selection {
  constructor() {
    this.parcel = new Parcels();
  }

  clearOldSelections(parcel) {
    this.parcel.removeOldParcelSelection(parcel)
  }

  cornSoybeans(parcel) {
    this.clearOldSelections(parcel)
    this.parcel.addParcelToMap(parcel, 'corn')
  }

  cornSoybeansWetland(parcel) {
    this.clearOldSelections(parcel)
    this.parcel.addParcelToMap(parcel, 'wetland')
  }

  cornSoybeansBufferStrip(parcel) {
    this.clearOldSelections(parcel)
    this.parcel.addParcelToMap(parcel, 'buffer')
  }

  perennialCover(parcel) {
    this.clearOldSelections(parcel)
    this.parcel.addParcelToMap(parcel, 'perennial_cover')
  }

  livestockTraditional(parcel) {
    this.clearOldSelections(parcel)
    this.parcel.addParcelToMap(parcel, 'livestock_traditional')
  }

  livestockRotational(parcel) {
    this.clearOldSelections(parcel)
    this.parcel.addParcelToMap(parcel, 'livestock_rotational')
  }

  residentialTraditional(parcel) {
    this.clearOldSelections(parcel)
    this.parcel.addParcelToMap(parcel, 'residential_traditional')
  }

  residentialLowImpact(parcel) {
    this.clearOldSelections(parcel)
    this.parcel.addParcelToMap(parcel, 'residential_low_impact')
  }

  commercialTraditional(parcel) {
    this.clearOldSelections(parcel)
    this.parcel.addParcelToMap(parcel, 'commercial_traditional')
  }

  commercialLowImpact(parcel) {
    this.clearOldSelections(parcel)
    this.parcel.addParcelToMap(parcel, 'commercial_low_impact')
  }

  addGolfCourse(parcel) {
    this.clearOldSelections(parcel)
    this.parcel.addParcelToMap(parcel, 'golf')
  }

  addpark(parcel) {
    this.clearOldSelections(parcel)
    this.parcel.addParcelToMap(parcel, 'park')
  }

  noTill(parcel) {
    this.parcel.addParcelToMap(parcel, 'no_till')
  }

  mulchTill(parcel) {
    this.parcel.addParcelToMap(parcel, 'mulch_till')
  }

  noTillCoverCrop(parcel, type) {
    this.parcel.addParcelToMap(parcel, 'no_till_cover')
  }

  stripTill(parcel, type) {
    this.parcel.addParcelToMap(parcel, 'strip_till')
  }

  update() {
    this.parcel.update()
  }

}
