import { Toast } from 'bootstrap';

export class ToastMessage {
  constructor(message, bgColor) {
    const toastMessageTemplate = document.getElementById('toast-message-template');

    this.toastEl = toastMessageTemplate.content.firstElementChild.cloneNode(true);
    this.toastEl.removeAttribute('id');
    this.toastEl.querySelector('.toast-body').textContent = message;
    if (bgColor !== undefined) {
      this.toastEl.classList.add(`bg-${bgColor}`);
    }
    this.toastEl.classList.remove('hide');
    toastMessageTemplate.closest('.toast-container').append(this.toastEl);

    this.toast = new Toast(this.toastEl);
  }

  show() {
    this.toast.show();
  }
}
