import { cable } from '@hotwired/turbo-rails';

(async () => {
  const connection = (await cable.getConsumer()).connection;

  document.addEventListener('turbo:before-fetch-request', (e) => {
    // See `ApplicationController#turbo_request?` and `#turbo_cable_connected?`
    e.detail.fetchOptions.headers = {
      ...e.detail.fetchOptions.headers,
      'X-Turbo-Drive': '1',
      'X-Turbo-Cable-Connected': connection.disconnected ? 0 : 1
    };
  });

  document.addEventListener('turbo:before-cache', () => {
    // See `ApplicationHelper#live_updated_turbo_frame`
    Array.from(document.getElementsByClassName('live-updated-turbo-frame')).forEach((liveUpdatedTurboFrame) => {
      if (connection.disconnected) {
        delete liveUpdatedTurboFrame.dataset.turboPermanent;
      } else {
        liveUpdatedTurboFrame.dataset.turboPermanent = 'true';
      }
    });
  });
})();
